'use strict';

var part_shapes = require('./helper/part_shapes'),
    part_view_mapping = require('./part_views/part_view_mapping'),
    label_view_mapping = require('./labels/label_list'),
    WireView = require('./part_views/wire_view'),
    grid_helper = require('./helper/grid_helper');

global.eappTranslationManager = null;

var View = function (circuit, part_list, label_list) {
    GOWN.Control.call(this);
    if (typeof circuit == 'string') {
        circuit = JSON.parse(circuit);
    }
    this.circuit = circuit || this.circuit;

    if (eappTranslationManager == null) {
        eappTranslationManager = new EAPP.TranslationManager();
    }

    part_view_mapping['wire'] = WireView;

    this._part_list = part_list || part_view_mapping;
    this._label_list = label_list || label_view_mapping;

    this.x = this.y = 0;
    this.width = this.height = 0;

    this._dc_voltage_terminals = [];

    this._part_view = new GOWN.Control();
    this._wire_view = new GOWN.Control();
    this._label_view = new GOWN.Control();

    this.addChild(this._wire_view);
    this.addChild(this._part_view);
    this.addChild(this._label_view);

    this._init_parts();
    this._init_labels();

    this.x += 25;
    this.y += 25;

    for (var id in this._parts) {
        this._add_part_to_bounds(this._parts[id]);
    }
    for (id in this._wires) {
        this._add_part_to_bounds(this._wires[id]);
    }
    for (id in this._labels) {
        this._add_part_to_bounds(this._labels[id]);
    }

    this.width += 25;
    this.height += 25;

    for (var i = 0; i < this._dc_voltage_terminals.length; i++) {
        this._dc_voltage_terminals[i].x = (-1) * this.x - 50;
        this._dc_voltage_terminals[i].draw();
    }
};

var p = View.prototype = Object.create(GOWN.Control.prototype);
module.exports = View;

p._init_parts = function () {
    this._parts = {};
    this._wires = {};

    var id, part, new_elm;
    var store_parts = this.circuit.data._parts,
        store_wires = this.circuit.data._wires;

    for (id in store_parts) {
        part = store_parts[id];

        if (part.type in this._part_list) {
            new_elm = new this._part_list[part.type](part);
        } else {
            console.log(part.type);
            continue;
        }

        if (part.type == 'dc_voltage_terminal') {
            this._dc_voltage_terminals.push(new_elm);
        }

        this.add_element(new_elm, part.props.x, part.props.y);
        new_elm.current_mode = 'scheme';
        new_elm.draw();
    }

    for (id in store_wires) {
        part = store_wires[id];
        new_elm = new this._part_list['wire'](part.id, part.points);
        this.add_element(new_elm, new_elm.x, new_elm.y);
        new_elm.current_mode = 'scheme';
        new_elm.draw();
    }
};

p._init_labels = function() {
    this._labels = {};

    var store_labels = this.circuit.data._labels;

    for (var id in store_labels) {
        var label = store_labels[id];
        var label_elm = new this._label_list[label.type](label);
        this.add_element(label_elm, label.position.x, label.position.y);
    }
};

p.add_element = function (elm, x, y) {
    var local = grid_helper.grid_to_local(x, y);
    this._add_part_to_position(elm, local);
    if (typeof elm.move_to == 'function') {
        elm.move_to(local.x, local.y);
    } else {
        elm.x = local.x;
        elm.y = local.y;
    }

    if (elm instanceof WireView) {
         if (!this._wires[elm.id]) {
            this._wires[elm.id] = elm;
            this._wire_view.addChild(elm);
        }
    } else if (elm.type && elm.type.indexOf('label') >= 0) {
        if (!this._labels[elm.id]) {
            this._labels[elm.id] = elm;
            this._label_view.addChild(elm);
        }
    } else if (!this._parts[elm.id]) {
        this._parts[elm.id] = elm;
        this._part_view.addChild(elm);
    }
};

p._add_part_to_position = function(elm, local) {
    var elm_x = 0,
        elm_y = 0;
    if (elm instanceof WireView) {
        for (var i = 0; i < elm.points.length; i++) {
            var p = elm.points[i];
            var p_local = grid_helper.grid_to_local(p.x, p.y);
            elm_x = (-1) * p_local.x;
            elm_y = (-1) * p_local.y;
            this.x = Math.max(this.x, elm_x);
            this.y = Math.max(this.y, elm_y);
        }
    } else {
        var text_bounds;
        var orientation = 0;
        if ('_data' in elm && 'props' in elm._data && 'orientation' in elm._data.props) {
            orientation = Math.degrees(elm._data.props.orientation);
        }
        if (local.x !== null && !isNaN(local.x)) {
            elm_x = (-1) * local.x;
            if (elm._text_label && orientation == 270) {
                text_bounds = elm._text_label.getBounds();
                elm_x += text_bounds.width;
            }
            this.x = Math.max(this.x, elm_x);
        }
        if (local.y !== null && !isNaN(local.y)) {
            elm_y = (-1) * local.y;
            if (elm._text_label && (orientation == 0 || orientation == 180)) {
                text_bounds = elm._text_label.getBounds();
                elm_y += text_bounds.height;
            }
            this.y = Math.max(this.y, elm_y);
        }
    }
};

p._add_part_to_bounds = function(elm) {
    var elm_width = elm.x + this.x;
    var elm_height = elm.y + this.y;

    var orientation = 0;
    if ('_data' in elm && 'props' in elm._data && 'orientation' in elm._data.props) {
        orientation = Math.degrees(elm._data.props.orientation);
    }

    if (elm instanceof WireView) {
        //wire
        for (var i = 0; i < elm.points.length; i++) {
            var p = elm.points[i];
            var p_local = grid_helper.grid_to_local(p.x, p.y);
            elm_width = p_local.x + this.x;
            elm_height = p_local.y + this.y;
            this.width = Math.max(this.width, elm_width);
            this.height = Math.max(this.height, elm_height);
        }
    } else if (elm.type == 'voltage_arrow_label') {
        //label
        elm_width += elm.end_point.x - elm.starting_point.x;
        elm_height += elm.end_point.y - elm.starting_point.y;
        console.log(elm_width, elm_height);
    } else if (orientation == 0) { //parts
        elm_width += elm.width;
        elm_height += elm.height;
    } else if (orientation == 90) {
        elm_height += elm.width;
        if (elm._text_label) {
            var text_bounds = elm._text_label.getBounds();
            elm_width += text_bounds.width;
        }
    } else if (orientation == 270) {
        elm_width += elm.height;
    }

    if (!(elm._type && elm._type == 'dc_voltage_terminal')) {
        this.width = Math.max(this.width, elm_width);
    }
    this.height = Math.max(this.height, elm_height);
};
