module.exports = {
    'measuring_data': {
        'scheme': {
            'size': {
                'x': 0, 'y': 0
            },
            'ports': []
        },
        'iso': {
            'size': {
                'x': 0, 'y': 0
            },
            'ports': []
        }
    },
    'measuring_tip': {
        'scheme': {
            'size': {
                'x': 2, 'y': 12
            },
            'ports': []
        },
        'iso': {
            'size': {
                'x': 2, 'y': 12
            },
            'ports': []
        }
    },
    'dot': {
        'scheme': {
            'size': {
                'x': 0, 'y': 0
            },
            'ports': []
        },
        'iso': {
            'size': {
                'x': 0, 'y': 0
            },
            'ports': []
        }
    },
    'diode': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'z_diode': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'led': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        }
    },
    'dc_motor': {
        'scheme': {
            'size': {
                'x': 16,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 16,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 16,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 16,
                    'y': 6
                }
            ]
        }
    },
    'shunt_dc_motor': {
        'scheme': {
            'size': {
                'x': 24,
                'y': 20
            },
            'ports': [
                {
                    'x': 4,
                    'y': 0
                },
                {
                    'x': 20,
                    'y': 0
                }
            ]
        },
        'iso': {
            'size': {
                'x': 14,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 14,
                    'y': 4
                }
            ]
        }
    },
    'series_dc_motor': {
        'scheme': {
            'size': {
                'x': 24,
                'y': 18
            },
            'ports': [
                {
                    'x': 4,
                    'y': 0
                },
                {
                    'x': 20,
                    'y': 0
                }
            ]
        },
        'iso': {
            'size': {
                'x': 14,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 14,
                    'y': 4
                }
            ]
        }
    },
    'lamp': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'push_button': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 10
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 10
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'push_button_pushed': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'push_button_no_on': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'push_button_no_off': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'push_button_nc_on': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'push_button_nc_off': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'ldr_resistor': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        }
    },
    'mosfet_p_left': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        }
    },
    'mosfet_p_right': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        }
    },
    'mosfet_n_left': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        }
    },
    'mosfet_n_right': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        }
    },
    'fet_p_left': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        }
    },
    'fet_p_right': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        }
    },
    'fet_n_left': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 6
                },
                {
                    'x': 0,
                    'y': 6
                }
            ]
        }
    },
    'fet_n_right': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 8,
                    'y': 6
                }
            ]
        }
    },
    'npn_right': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 0
                },
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 8,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 0
                },
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 8,
                    'y': 4
                }
            ]
        }
    },
    'npn_left': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 0
                },
                {
                    'x': 8,
                    'y': 8
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 0
                },
                {
                    'x': 8,
                    'y': 8
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        }
    },
    'pnp_right': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 0
                },
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 8,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 0
                },
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 8,
                    'y': 4
                }
            ]
        }
    },
    'pnp_left': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 0
                },
                {
                    'x': 8,
                    'y': 8
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 0
                },
                {
                    'x': 8,
                    'y': 8
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        }
    },
    'ntc': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'ptc': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'coil_2': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        }
    },
    'coil': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        }
    },
    'capacitor': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'potentiometer': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 6
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 8,
                    'y': 4
                },
                {
                    'x': 8,
                    'y': 0
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 6
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 8,
                    'y': 4
                },
                {
                    'x': 8,
                    'y': 0
                }
            ]
        }
    },
    'car_battery': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 4
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 4
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        }
    },
    'dc_voltage_source': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 4
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 8,
                    'y': 4
                },
                {
                    'x': 0,
                    'y': 4
                }
            ]
        }
    },
    'resistor': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'switch_on': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        }
    },
    'switch_off': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        }
    },
    'switch_no_on': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'switch_no_off': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'switch_nc_on': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'switch_nc_off': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'opener_on': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        }
    },
    'opener_off': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 12,
                    'y': 2
                }
            ]
        }
    },
    'toggle_switch_bottom': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'toggle_switch_top': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 12,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 8
                }
            ]
        }
    },
    'relais_with_diode': {
        'scheme': {
            'size': {
                'x': 16,
                'y': 14
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 16,
                    'y': 8
                },
                {
                    'x': 0,
                    'y': 12
                },
                {
                    'x': 16,
                    'y': 12
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 6
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                },
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 8,
                    'y': 4
                }
            ]
        }
    },
    'relais_with_diode_closed': {
        'scheme': {
            'size': {
                'x': 16,
                'y': 14
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 16,
                    'y': 8
                },
                {
                    'x': 0,
                    'y': 12
                },
                {
                    'x': 16,
                    'y': 12
                }
            ]
        },
        'iso': {
            'size': {
                'x': 16,
                'y': 14
            },
            'ports': [
                {
                    'x': 0,
                    'y': 8
                },
                {
                    'x': 16,
                    'y': 8
                },
                {
                    'x': 0,
                    'y': 12
                },
                {
                    'x': 16,
                    'y': 12
                }
            ]
        }
    },
    'ground': {
        'scheme': {
            'size': {
                'x': 4,
                'y': 8
            },
            'ports': [
                {
                    'x': 2,
                    'y': 0
                }
            ]
        },
        'iso': {
            'size': {
                'x': 4,
                'y': 8
            },
            'ports': [
                {
                    'x': 2,
                    'y': 0
                }
            ]
        }
    },
    'dc_voltage_terminal': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'y': 2
                }
            ]
        }
    },
    'multimeter': {
        'scheme': {
            'size': {
                'x': 0,
                'y': 0
            },
            'ports': []
        },
        'iso': {
            'size': {
                'x': 0,
                'y': 0
            },
            'ports': []
        }
    },
    'amperemeter': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        }
    },
    'voltmeter': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        }
    },
    'ohmmeter': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        }
    },
    'wattmeter': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 6,
                    'y': 0
                },
                {
                    'x': 12,
                    'y': 6
                },
                {
                    'x': 6,
                    'y': 12
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 12
            },
            'ports': [
                {
                    'x': 0,
                    'y': 6
                },
                {
                    'x': 6,
                    'y': 0
                },
                {
                    'x': 12,
                    'y': 6
                },
                {
                    'x': 6,
                    'y': 12
                }
            ]
        }
    },
    'oscilloscope': {
        'scheme': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 12,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 12,
                    'y': 4
                }
            ]
        }
    },
    'fuse': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 4
            },
            'ports': [
                {
                    'x': 0,
                    'y': 2
                },
                {
                    'x': 8,
                    'y': 2
                }
            ]
        }
    },
    'solar_cell': {
        'scheme': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 8,
                    'y': 4
                }
            ]
        },
        'iso': {
            'size': {
                'x': 8,
                'y': 8
            },
            'ports': [
                {
                    'x': 0,
                    'y': 4
                },
                {
                    'x': 8,
                    'y': 4
                }
            ]
        }
    }
};
