'use strict';

var ArrowLabel = require('./arrow_label'),
    PartLabel = require('./part_label'),
    gridHelper = require('../helper/grid_helper');

/**
 * An arrow to display voltage directions
 * @class VoltageArrowLabel
 * @extends ArrowLabel
 * @constructor
 */
var VoltageArrowLabel = function (props) {
    ArrowLabel.call(this, props);

    this.type = 'voltage_arrow_label';
    this.starting_point = props.points.start;
    this.end_point = props.points.end;

    this.name = eappTranslationManager.t('part_label.' + this.type);
    this._subscript = typeof props.subscript == 'undefined' ? true : props.subscript;
    this._color = this._arrow._color = this._label._color = 0x0000FF;

    this.removeChild(this._label);

    this._label = new PartLabel(this.name, this._text, this._subscript, this._arrow._color);
    this.center_point = this._calculate_center_point(this.starting_point, this.end_point);
    this._delta_pivot = 7;
    this._label_pivot = {
        'x': this._label.pivot.x,
        'y': this._label.pivot.y
    };
    this._label.pivot.x = -25 * this._normal.x;
    this._label.pivot.y = this._label_pivot.y + this._delta_pivot;
    this.addChild(this._label);
};

var p = VoltageArrowLabel.prototype = Object.create(ArrowLabel.prototype);

p._calculate_normal = function(start, end) {
    this._normal = ArrowLabel.prototype._calculate_normal.call(this, start, end);
    this._label.pivot.x = -25 * this._normal.x;
    return this._normal;
};

p.draw = function(){
    this._arrow.starting_point = EAPP.View.helper.gridHelper.grid_to_local(this._start.x, this._start.y);
    this._arrow.end_point      = EAPP.View.helper.gridHelper.grid_to_local(this._end.x,   this._end.y);
    this.center_point = this._calculate_center_point(this.starting_point, this.end_point);
    this._normal = this._calculate_normal(this.starting_point, this.end_point);
};

/**
 * @property center_point
 * The center point of the arrow
 */
Object.defineProperty(p, 'center_point', {
    get: function() {
        var distance = 10;
        var super_unit_normal = this.normal;

        return gridHelper.local_to_grid(this._center_point.x + Math.round(super_unit_normal.x1 * distance), this._center_point.y + Math.round(super_unit_normal.x2 * distance));
    },
    set: function(newvalue) {
        newvalue = gridHelper.grid_to_local(newvalue.x, newvalue.y);
        this._center_point = newvalue;
        this._label.x = newvalue.x;
        this._label.y = newvalue.y;
    }
});

/**
 * @property normal
 * The normal of the arrow, directed to alyways point upwards
 */
Object.defineProperty(p, 'normal', {
    get: function() {
        var super_normal = this._arrow.unit_normal;
        if (super_normal.x2 > 0) {
            super_normal.x1 *= -1;
            super_normal.x2 *= -1;
        }
        return super_normal;
    }
});

/**
 * @property starting_point
 * The starting point of the arrow
 */
Object.defineProperty(p, 'starting_point', {
    get: function() {
        return { x: this._start.x, y: this._start.y };
    },
    set: function(newvalue) {
        this._start = {
            x:newvalue.x,
            y:newvalue.y
        }
    }
});

/**
 * @property end_point
 * The end point of the arrow
 */
Object.defineProperty(p, 'end_point', {
    get: function() {
        return { x: this._end.x, y: this._end.y };
    },
    set: function(newvalue) {
        this._end = {
            x:newvalue.x,
            y:newvalue.y
        }
    }
});

/**
 * Calculates and returns the hitbox of the arrow
 * @return {PIXI.Polygon}
 */
p._get_hitbox = function(opt_extended) {
    opt_extended = typeof opt_extended === 'undefined' ? true : opt_extended;
    // taking normal from internal arrow onject
    var super_unit_normal = this._arrow.unit_normal;

    // create inverted normal object
    var super_unit_normal_inverted = this._arrow.unit_normal;
    super_unit_normal_inverted.x1 *= -1;
    super_unit_normal_inverted.x2 *= -1;

    // Bring both normal vectors length to grid cell width
    var spacing = gridHelper.spacing;

    super_unit_normal.x1 = Math.round(super_unit_normal.x1 * spacing);
    super_unit_normal.x2 = Math.round(super_unit_normal.x2 * spacing);

    super_unit_normal_inverted.x1 = Math.round(super_unit_normal_inverted.x1 * spacing);
    super_unit_normal_inverted.x2 = Math.round(super_unit_normal_inverted.x2 * spacing);

    // get start + end point from _arrow object
    var super_starting_point = {
        'x': this._arrow.starting_point.x,
        'y': this._arrow.starting_point.y
    };
    var super_end_point = {
        'x': this._arrow.end_point.x,
        'y': this._arrow.end_point.y
    };
    if (opt_extended) {
        var ssp = super_starting_point;
        var sep = super_end_point;

        var l = this._arrow.length;
        var minus = {
            'x': (ssp.x - sep.x)/l,
            'y': (ssp.y - sep.y)/l
        };
        var plus = {
            'x': (sep.x - ssp.x)/l,
            'y': (sep.y - ssp.y)/l
        };
        ssp.x += minus.x * spacing;
        ssp.y += minus.y * spacing;

        sep.x += plus.x * spacing;
        sep.y += plus.y * spacing;
        super_starting_point = ssp;
        super_end_point = sep;
    }

    // calculating the points of the hitbox
    var x1 = super_starting_point.x + super_unit_normal.x1;
    var y1 = super_starting_point.y + super_unit_normal.x2;

    var x2 = super_starting_point.x + super_unit_normal_inverted.x1;
    var y2 = super_starting_point.y + super_unit_normal_inverted.x2;

    var x3 = super_end_point.x + super_unit_normal_inverted.x1;
    var y3 = super_end_point.y + super_unit_normal_inverted.x2;

    var x4 = super_end_point.x + super_unit_normal.x1;
    var y4 = super_end_point.y + super_unit_normal.x2;

    // creating points of those coordinates
    var point1 = new PIXI.Point(x1, y1);
    var point2 = new PIXI.Point(x2, y2);
    var point3 = new PIXI.Point(x3, y3);
    var point4 = new PIXI.Point(x4, y4);

    var pointarray = [point1, point2, point3, point4];

    // turning the points into a polygon which is then returned
    return new PIXI.Polygon(pointarray);
};

/**
 * The labels text attribute.
 * Performs an automatic update on set
 * @property text
 */
Object.defineProperty(p, 'text', {
    get: function () {
        return this._text;
    },
    set: function (newvalue) {
        this._text = newvalue;
        this._update_label();
    }
});

/**
 * The subscript option for the partlabel text.
 * Performs an automatic update on set.
 * @property subscript
 */
Object.defineProperty(p, 'subscript', {
    get: function () {
        return this._subscript;
    },
    set: function (newvalue) {
        this._subscript = newvalue;
        this._label.pivot = {
            'x': this._label_pivot.x,
            'y': this._label_pivot.y + this._delta_pivot
        };
        this._update_label();
    }
});

/**
 * Updates the labels text and subscript option
 */
p._update_label = function() {
    if (this._label && this._label.name_text) {
        this._label.name_text = this.text;
        this._label.subscript = this.subscript;
    }
};

module.exports = VoltageArrowLabel;