module.exports = {
    normal: function(p1, p2) {
        var dx = p2.x - p1.x;
        var dy = p2.y - p1.y;
        var norm = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
        dx /= norm;
        dy /= norm;
        if (dx > 0) {
            dx *= -1;
        }
        if (dy < 0) {
            dy *= -1;
        }
        //console.log('CALCULATE NORMAL', p1, p2, { 'x': dy, 'y': dx });
        return {
            'x': dy,
            'y': dx
        }
    },
    center: function (p1, p2) {
        /* Simple vector calculation to determine the center point */
        var center_point = new PIXI.Point();
        center_point.x = p2.x - p1.x;
        center_point.y = p2.y - p1.y;

        center_point.x /= 2;
        center_point.y /= 2;

        center_point.x += p1.x;
        center_point.y += p1.y;

        return center_point;
    }
};