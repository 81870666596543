'use strict';

var PartView = require('./part_view'),
    part_shapes = require('../helper/part_shapes'),
    grid_helper = require('../helper/grid_helper');

var DCVoltageTerminalView = function(part) {
    PartView.call(this, part);
};

var p = DCVoltageTerminalView.prototype = Object.create(PartView.prototype);
module.exports = DCVoltageTerminalView;

p.draw = function() {
    this.removeChildren();

    var dimension = {
        'x': this.parent.parent.width + 100,
        'y': this.height
    };
    this.width = dimension.x;
    dimension = grid_helper.local_to_grid(dimension.x, dimension.y);
    dimension = grid_helper.grid_to_local(dimension.x, dimension.y);
    var symbol = new PIXI.Graphics();

    symbol.f(part_shapes['background']).ss(part_shapes['line_width']*0.25).s(part_shapes['color']).moveTo(0, dimension.y/2).lineTo(dimension.x, dimension.y/2);

    symbol.x = 0;
    symbol.y = 0;
    this.addChild(symbol);

    this.draw_scheme_textlabel();
};

p.draw_scheme_textlabel = function() {
    this._text_label.x = this.width / 2;
    this._text_label.y = 20;

    this.addChild(this._text_label);
};