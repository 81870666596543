'use strict';

var Label = require('./label');

var PartLabel = function (label_text, name_text, subscript, color) {
    Label.call(this)

    this._label_text = label_text
    this._name_text = name_text
    this._color = color || 0x000000
    this._subscript = typeof subscript === 'undefined' ? true : subscript
    this._scale = {}

    var textStyle = {'fontWeight': 'bold', 'fontSize': 24, 'fontFamily': 'Arial', 'fill': this._color}

    this._text = new PIXI.Text(this._label_text, textStyle)
    this._text.pivot.x = this._text.width
    this._text.pivot.y = this._text.height
    this.addChild(this._text)

    this._textHeight = this._text.height

    this._name = new PIXI.Text(this._name_text, textStyle)
    this._name.style.fontSize = subscript || 12
    this._name.pivot.y = subscript ? this._name.height - 5 : this._textHeight
    this.addChild(this._name)

    this._nameHeight = this._name.height
}

var p = PartLabel.prototype = Object.create(Label.prototype);

p._updateText = function () {
    this._text.text = this._label_text;
    this._text.style.fill = this._color;
};

p._updateName = function () {
    this._name.text = this._name_text;
    this._name.pivot.y = this._subscript ? this._nameHeight - 5 : this._textHeight
    this._name.style.fontSize = this._subscript ? 12 : 24;
}

p.update = function () {
    this._updateText();
    this._updateName();
}

Object.defineProperty(p, 'label_text', {
    get: function() {
        return this._label_text;
    },
    set: function(newvalue) {
        this._label_text = newvalue;
        this._updateText();
    }
});

Object.defineProperty(p, 'name_text', {
    get: function() {
        return this._name_text;
    },
    set: function(newvalue) {
        this._name_text = newvalue;
        this._updateName();
    }
});

Object.defineProperty(p, 'subscript', {
    get: function() {
        return this._subscript;
    },
    set: function(newvalue) {
        this._subscript = newvalue;
        this._updateName();
    }
});

Object.defineProperty(p, 'color', {
    get: function() {
        return this._color;
    },
    set: function(newvalue) {
        this._color = newvalue;
        this._updateText();
    }
});

Object.defineProperty(p, 'scale', {
    get: function() {
        return this._scale;
    },
    set: function(scale) {
        this._scale.x = this._text.scale.x = this._name.scale.x = scale.x;
        this._scale.y = this._text.scale.y = this._name.scale.y = scale.y;
    }
});

module.exports = PartLabel;