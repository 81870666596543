'use strict';

var PartView = require('./part_view'),
    part_sizes = require('../helper/part_sizes'),
    grid_helper = require('../helper/grid_helper');

var MotorView = function(part) {
    PartView.call(this, part);
};

var p = MotorView.prototype = Object.create(PartView.prototype);
module.exports = MotorView;

p.draw = function() {
    PartView.prototype.draw.call(this);

    var text_cont = new GOWN.Control();
    text_cont.rotation = this.orientation;
    this.addChild(text_cont);
    var elm_text = new PIXI.Text('M', {'fontWeight': 'bold', 'fontSize': 32, 'fontFamily': 'Arial', 'fill': 0x000000});
    var size = part_sizes[this._type]['scheme'].size;
    var width = text_cont.width = grid_helper.spacing * size.x / 2;
    var height = text_cont.height = grid_helper.spacing * size.y / 2;
    elm_text.anchor = {'x': 0.5, 'y': 0.5};
    elm_text.x = width / 2;
    elm_text.y = height / 2;
    elm_text.rotation = -this.orientation;

    text_cont.addChild(elm_text);
};