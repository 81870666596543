'use strict';

var PartLabel = require('../labels/part_label'),
    part_shapes = require('../helper/part_shapes'),
    part_sizes = require('../helper/part_sizes'),
    grid_helper = require('../helper/grid_helper');

var PartView = function(part) {
    GOWN.Control.call(this);

    this._id = part.id;
    this._type = part.type;
    this._name = part.props.name || '';
    this._subscript = typeof part.props.subscript == 'undefined' ? true: part.props.subscript;

    if (this._type in part_sizes && part_sizes[this._type]['scheme'] && 'size' in part_sizes[this._type]['scheme']) {
        var size = part_sizes[this._type]['scheme'];
        this.width = size.size.x/2 * grid_helper.spacing;
        this.height = size.size.y/2 * grid_helper.spacing;
    }

    this.x = this.y = 0;
    this._orientation = part.props.orientation || 0;

    this._data = part;

    var label = eappTranslationManager.t('part_label.' + this._type);

    this._text_label = new PartLabel(label, this.name, this._subscript);
    this.addChild(this._text_label);

    this._show_port_label = false;
    this._port_label = [];
    this._function_digits = [];
    this._rank_digits = [];
};

var p = PartView.prototype = Object.create(GOWN.Control.prototype);
module.exports = PartView;

p.draw = function() {
    this.removeChildren();

    var symbol = new PIXI.Graphics();
    part_shapes['draw'](symbol, this._type);
    symbol.x = symbol.y = 0;
    symbol.rotation = Math.radians(this.orientation);
    symbol.scale.x = symbol.scale.y = 0.25;
    this.addChild(symbol);
    this._draw_port_label();
    this.draw_textlabel();
};

p.draw_textlabel = function() {
    var orientation = this.orientation;
    if (orientation == 0) {
        this._text_label.x = this.width / 2;
        this._text_label.y = -10;
    } else if (orientation == 90) {
        this._text_label.x = this.height / 2;
        this._text_label.y = this.width / 2;
    } else if (orientation == 180) {
        this._text_label.x = -this.width / 2;
        this._text_label.y = -this.height - 10;
    } else if (orientation == 270) {
        this._text_label.x = -this.height / 2;
        this._text_label.y = -this.height / 2;
    }

    this.addChild(this._text_label);
};

p._draw_port_label = function() {
    var size = part_sizes[this._type]['scheme'];
    var len = Math.min(size.ports.length, this._port_label.length);
    var orientation = this.orientation;
    for (var i = 0; i < len; i++) {
        var port = size.ports[i];

        var label = new PIXI.Text(this._port_label[i], {'fontWeight': 'bold', 'fontSize': 12, 'fontFamily': 'Arial', 'fill': 0x000000});
        label.anchor = new PIXI.Point(0.5, 0.5);

        var port_x = port.x,
            port_y = port.y;

        if (port_x == 0) {
            port_x = 1;
        } else if (port_x == size.size.x) {
            port_x = size.size.x - 1;
        }

        var x = port_x,
            y = port_y - 1;

        if (orientation == 90) {
            x = -(port_y - 1);
            y = port_x;
        } else if (orientation == 180) {
            x = -x;
            y = -y;
        } else if (orientation == 270) {
            x = port_y - 1;
            y = -port_x;
        }

        var coords = grid_helper.grid_to_local(x, y);
        label.x = coords.x;
        label.y = coords.y;

        this.addChild(label);
    }
};

p._update_port_label = function() {
    this._port_label = [];
    var len = Math.max(this._function_digits.length, this._rank_digits.length);
    for (var i = 0; i < len; i++) {
        var lbl = '';
        if (i < this._function_digits.length) {
            lbl += this._function_digits[i];
        }
        if (i < this._rank_digits.length) {
            lbl += this._rank_digits[i];
        }
        this._port_label.push(lbl);
    }
};

p._get_orientation = function() {
    return this._orientation;
};

p._set_orientation = function(orientation) {
    this._orientation = orientation;
};

Object.defineProperty(p, 'id', {
    get: function() {
        return this._id;
    }
});

Object.defineProperty(p, 'name', {
    get: function() {
        return this._name;
    }
});

Object.defineProperty(p, 'orientation', {
    get: p._get_orientation,
    set: p._set_orientation
});