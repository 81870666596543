'use strict';

var Label = require('./label'),
    ArrowHead = require('./arrow_head'),
    PartLabel = require('./part_label');

/**
 * An arrow to display voltage directions
 * @class CurrentArrowLabel
 * @extends ArrowLabel
 * @param {PIXI.Point}  start   The starting point of the arrow
 * @param {PIXI.Point}  end     The endpoint of the arrow
 * @param {String}      id      The id of the arrow
 * @constructor
 */
var CurrentArrowLabel = function (props) {
    Label.call(this);

    this.type = 'current_arrow_label';
    this.name = eappTranslationManager.t('part_label.' + this.type);

    this._selected = props.selected || false;

    this._text = props.text || '';

    this._subscript = typeof props.subscript == 'undefined' ? true : props.subscript;

    this._color = 0xFF0000;
    this._arrow = new ArrowHead(this._color);
    this._arrow.pivot = new PIXI.Point(0, -5);
    this._label = new PartLabel(this.name, this._text, this._subscript, this._color);
    this._label.color = this._color;
    if ((props.orientation == CurrentArrowLabel.DIRECTION.UP) || (props.orientation == CurrentArrowLabel.DIRECTION.DOWN)) {
        this._label.pivot = new PIXI.Point(-20, -12);
    } else {
        this._label.pivot = new PIXI.Point(0, 15);
    }
    this.id = props.id || '';

    //this._arrow.position = this._label.position = EAPP.View.helper.gridHelper.grid_to_local(props.position.x, props.position.y);
    this._arrow.rotation = props.orientation;

    this.addChild(this._label);
    this.addChild(this._arrow);
};

/**
 * The directions the arrow can point to
 * @const
 * @static
 * @type {Object}
 */
CurrentArrowLabel.DIRECTION = {
    'DOWN': 0,
    'UP': Math.PI,
    'LEFT': Math.PI * 0.5,
    'RIGHT': Math.PI * 1.5
};

var p = CurrentArrowLabel.prototype = Object.create(Label.prototype);
module.exports = CurrentArrowLabel;

p.get_size = function () {
    return {
        'x': 4,
        'y': 4
    };
};

p.getBoundsParent = p.getBounds;

p.getBounds = function () {
    var pos = this.starting_point;
    pos.x -= 2;
    pos.y -= 2;
    var size = this.get_size();
    var grid = view.current_screen.content.grid;
    var context = view.current_screen.content.service.context;
    var bounds = this.getBoundsParent();
    if (context == 'scheme') {
        var global_pos = grid.grid_to_global(pos.x, pos.y);
        var global_size = grid.grid_to_global(size.x, size.y);
        bounds.x = global_pos.x;
        bounds.y = global_pos.y;
        bounds.width = global_size.x;
        bounds.height = global_size.y;
    } else if (context == 'iso') {
        var top = grid.grid_to_global(pos.x, pos.y);
        var bottom = grid.grid_to_global(pos.x + size.x, pos.y + size.y);
        var left = grid.grid_to_global(pos.x, pos.y + size.y);
        var right = grid.grid_to_global(pos.x + size.x, pos.y);
        bounds.x = left.x;
        bounds.y = top.y;
        bounds.width = Math.abs(right.x - left.x);
        bounds.height = Math.abs(bottom.y - top.y);
    }
    return bounds;
};
