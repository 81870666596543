var ArrowHead = require('./arrow_head');

'use strict';

/**
 * The Arrow class encapsulates the drawing of a simple arrow
 * @class Arrow
 * @extends PIXI.Graphics
 * @param {PIXI.Point}  start   the start point of the arrow
 * @param {PIXI.Point}  end     the end point of the arrow
 * @constructor
 */
var Arrow = function (start, end) {
    PIXI.Graphics.call(this);

    this.flip = eappTranslationManager.t('options.passive_sign_convention');

    this._starting_point = start;
    this._end_point = end;

    /**
     * The color of the arrow
     * @member {Number} _color
     */
    this._color = 0x0000FF;

    this.dirtyFlag = true;
};

var p = Arrow.prototype = Object.create(PIXI.Graphics.prototype);
module.exports = Arrow;

/**
 * Draws the arrow
 */
p.draw = function () {
    this.clear();
    this.removeChildren();

    // Calculate the angle of the arrow
    var s = this.flip ? this._starting_point : this._end_point;
    var e = this.flip ? this._end_point : this._starting_point;

    var width = e.x - s.x;
    var height = e.y - s.y;

    this._angle = -Math.atan2(width, height);


    // Draw a basic arrow
    this.lineStyle(2, this._color);
    this.moveTo(this._starting_point.x, this._starting_point.y);
    this.lineTo(this._end_point.x, this._end_point.y);
    var head = new ArrowHead(this._color);
    head.rotation = this._angle;
    head.position = this.flip ? this._end_point : this._starting_point;
    this.addChild(head);
};

/**
 * @property color
 * Defines the color of the arrow
 * This properiy automatically redraws the arrow on change
 */
Object.defineProperty(p, 'color', {
    get: function() {
        return this._color;
    },
    set: function(newvalue) {
        this._color = newvalue;
        this.dirtyFlag = true;
    }
});

/**
 * @property starting_point
 * Defines the starting point of the arrow
 * This property automatically redraws the arrow on change
 */
Object.defineProperty(p, 'starting_point', {
    get: function() {
        return this._starting_point;
    },
    set: function(newvalue) {
        this._starting_point = newvalue;
        this.dirtyFlag = true;
    }
});

/**
 * @property end_point
 * Defines the end point of the arrow
 * This property automatically redraws the arrow on change
 */
Object.defineProperty(p, 'end_point', {
    get: function() {
        return this._end_point;
    },
    set: function(newvalue) {
        this._end_point = newvalue;
        this.dirtyFlag = true;
    }
});

/**
 * @property length
 * Returns the length of the arrow
 */
Object.defineProperty(p, 'length', {
    get: function() {
        var x = this.end_point.x - this.starting_point.x;
        var y = this.end_point.y - this.starting_point.y;
        var length = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        return length;
    }
});

/**
 * @property normal
 * Returns the left-normal of the arrow
 */
Object.defineProperty(p, 'normal', {
    get: function() {
        var dx = this.end_point.x - this.starting_point.x;
        var dy = this.end_point.y - this.starting_point.y;
        return {
            'x1': -dy,
            'x2': dx
        };
    }
});

/**
 * @property unit_normal
 * Returns the unified normal vector
 */
Object.defineProperty(p, 'unit_normal', {
    get: function() {
        var unit_normal = this.normal;
        unit_normal.x1 /= this.length;
        unit_normal.x2 /= this.length;

        return unit_normal;
    }
});

p.updateTransform = function(){
    if(this.dirtyFlag){
        this.draw();
        this.dirtyFlag = false;
    }

    PIXI.Graphics.prototype.updateTransform.call(this)
};