'use strict';
/**
 * The head of an arrow - in the specified color
 * @class ArrowHead
 * @extends PIXI.Graphics
 * @param  {Number} color The color of the arrowhead
 * @constructor
 */
var ArrowHead = function (color) {
    PIXI.Graphics.call(this);
    var distance = 10;
    this.lineStyle(2, color)
        .beginFill(color)
        .moveTo(-distance / 2, -distance)
        .lineTo(0, 0)
        .lineTo(distance / 2, -distance)
        .lineTo(-distance / 2, -distance)
        .endFill();
    this.pivot = new PIXI.Point(0, 0);
};

ArrowHead.prototype = Object.create(PIXI.Graphics.prototype);
module.exports = ArrowHead;