module.exports={
    "part_names": {
        "measuring_tip":"Measuring Tip",
        "diode": "Diode",
        "z_diode": "Z-Diode",
        "led": "LED",
        "led_red": "LED Red",
        "led_green": "LED Green",
        "led_orange": "LED Orange",
        "led_yellow": "LED Yellow",
        "dc_motor": "DC Motor",
        "shunt_dc_motor": "Shunt Motor",
        "series_dc_motor": "Inverse Speed Motor",
        "lamp": "Lamp",
        "push_button": "Push Button",
        "push_button_pushed": "Push Button",
        "push_button_no_on": "Push Button",
        "push_button_no_off": "Push Button",
        "push_button_nc_on": "Push Button",
        "push_button_nc_off": "Push Button",
        "ldr_resistor": "LDR",
        "mosfet_p_left": "P-Channel MOSFET",
        "mosfet_p_right": "P-Channel MOSFET",
        "mosfet_n_left": "N-Channel MOSFET",
        "mosfet_n_right": "N-Channel MOSFET",
        "fet_p_left": "P-Channel FET",
        "fet_p_right": "P-Channel FET",
        "fet_n_left": "N-Channel FET",
        "fet_n_right": "N-Channel FET",
        "npn": "NPN Transistor",
        "pnp": "PNP Transistor",
        "npn_right": "NPN Transistor",
        "npn_left": "NPN Transistor",
        "pnp_right": "PNP Transistor",
        "pnp_left": "PNP Transistor",
        "ntc": "NTC",
        "ptc": "PTC",
        "coil_2": "Coil",
        "coil": "Coil",
        "capacitor": "Capacitor",
        "potentiometer": "Potentiometer",
        "car_battery": "Car Battery",
        "dc_voltage_source": "DC Voltage Source",
        "resistor": "Resistor",
        "switch_on": "Switch",
        "switch_off": "Switch",
        "switch_no_on": "Switch",
        "switch_no_off": "Switch",
        "switch_nc_on": "Switch",
        "switch_nc_off": "Switch",
        "opener_on": "Break Contact",
        "opener_off": "Break Contact",
        "fuse": "Fuse",
        "toggle_switch_bottom": "Toggle Switch",
        "toggle_switch_top": "Toggle Switch",
        "relais": "Relais",
        "relais_with_diode": "Relais with Diode",
        "relais_with_diode_closed": "Relais with Diode",
        "contactor": "Contactor",
        "ground": "Ground",
        "dc_voltage_terminal": "DC Voltage Source",
        "wire": "Wire",
        "amperemeter": "Amperemeter",
        "voltmeter": "Voltmeter",
        "ohmmeter": "Ohmmeter",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimeter",
        "wattmeter": "Wattmeter",
        "voltage_arrow_label": "Voltage Arrow",
        "current_arrow_label": "Current Arrow",
        "minus": "Minus",
        "plus": "Plus",
        "ampere_plus": "Ampere",
        "ampere_minus": "Ampere",
        "voltage_plus": "Voltage",
        "voltage_minus": "Voltage",
        "dot": "Contact point",
        "multiselection": "Multi-Selection",
        "solar_cell": "Solar Cell"
    },
    "part_label": {
        "measuring_tip":"M T",
        "diode": "V",
        "z_diode": "V",
        "led": "V",
        "dc_motor": "M",
        "shunt_dc_motor": "M",
        "series_dc_motor": "M",
        "lamp": "E",
        "push_button": "S",
        "push_button_pushed": "S",
        "ldr_resistor": "R",
        "mosfet_p_left": "V",
        "mosfet_p_right": "V",
        "mosfet_n_left": "V",
        "mosfet_n_right": "V",
        "fet_p_left": "V",
        "fet_p_right": "V",
        "fet_n_left": "V",
        "fet_n_right": "V",
        "npn_right": "V",
        "npn_left": "V",
        "pnp_right": "V",
        "pnp_left": "V",
        "ntc": "R",
        "ptc": "R",
        "coil_2": "L",
        "coil": "L",
        "capacitor": "C",
        "potentiometer": "R",
        "car_battery": "G",
        "dc_voltage_source": "G",
        "dc_voltage_terminal": "G",
        "dot": "",
        "resistor": "R",
        "switch_on": "S",
        "switch_off": "S",
        "opener_on": "S",
        "opener_off": "S",
        "fuse": "F",
        "toggle_switch_bottom": "S",
        "toggle_switch_top": "S",
        "relais_with_diode": "K",
        "relais_with_diode_closed": "K",
        "contactor": "Q",
        "ground": "G",
        "voltage_arrow_label": "v",
        "current_arrow_label": "i",
        "amperemeter": "A",
        "voltmeter": "V",
        "ohmmeter": "\u03a9",
        "wattmeter": "P",
        "oscilloscope": "O",
        "solar_cell": "X"
    },
    "partCategories": {
        "resistors": "Resistors",
        "capacitors": "Capacitors and Coils",
        "electricalSwitches": "Electrical Switches",
        "mechanicalSwitches": "Mechanical Switches",
        "electroMechanicalSwitches": "Electromechanical Switches",
        "illuminant": "Illuminants",
        "motors": "Motors",
        "powerSupply": "Power Supplies",
        "measurement": "Measurement Devices",
        "tools": "Tools",
        "amperemeter": "Amperemeter",
        "voltmeter": "Voltmeter",
        "ohmmeter": "Ohmmeter",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimeter",
        "wattmeter": "Wattmeter",
        "kfz": "Automobile"
    },
    "label": {
        "voltage_arrow_label": "Voltage",
        "current_arrow_label": "Current",
        "voltage": "Voltage",
        "ampere": "Ampere",
        "resistance": "Resistance",
        "inductance": "Inductance",
        "capacity": "Capacity",
        "z_voltage": "Zener voltage",
        "min_resistance": "Minimum Resistance",
        "max_resistance": "Maximum Resistance",
        "divider": "Current Resistance",
        "light": "Light",
        "temperature": "Temperature",
        "load": "Load",
        "shortcircuit": "Short Circuit",
        "infinity": "Infinite",
        "contact": "Contact",
        "nc": "NC",
        "no": "NO",
        "left": "Left",
        "right": "Right",
        "transistor_basis": "Base contact",
        "irradiance": "Irradiance"
    },
    "options": {
        "fahrenheit": true,
        "wiring_symbols": "IEC",
        "passive_sign_convention": true,
        "commaSeperator": false
    },
    "circuit_types": {
        "generic": "Generic",
        "enclosure": "Enclosure",
        "domestic_installation": "Domestic installation",
        "automobile": "Automobile"
    },
    "toolnames": {
        "multiselect": "Multi-Selection",
        "wire": "Wire",
        "voltageLabel": "Voltagearrow",
        "currentLabel": "Currentarrow"
    },
    "ui": {
        "simulation": "Simulation",
        "editor": "Editor",
        "toolbar": "Tools",
        "part_list": "Parts",
        "save": "Save",
        "circuit": "Circuit",
        "new_circuit": "New Circuit",
        "new": "New",
        "reset": "Reset",
        "quality": "Quality",
        "magnify": "Magnify",
        "decrease": "Decrease",
        "maximize": "Maximize",
        "minimize": "Minimize",
        "name": "Name",
        "rotate": "Rotate",
        "delete": "Delete",
        "perspective": "Perspective",
        "overview": "Overview",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimeter",
        "wattmeter": "Wattmeter",
        "toggle": "Toggle",
        "edit": "Edit",
        "simulate": "Simulate",
        "zoom_in": "Zoom-in",
        "zoom_out": "Zoom-out",
        "2d_view": "2D View",
        "scheme": "2D View",
        "3d_view": "3D View",
        "iso": "3D View",
        "views": "Views",
        "options": "Options",
        "file": "File",
        "subscript": "Subscript",
        "open": "Open",
        "save_as": "Save as",
        "settings": "Settings",
        "close": "Close",
        "release": "Release settings",
        "partDestroyability": "Parts destroyable",
        "showContactLabels": "Show Contactlabels",
        "startView3d": "3D-View on start",
        "openSideMenuOnStart": "Sidebar toggled on start",
        "voltageEditable": "Voltage sources editable",
        "cancel": "Cancel",
        "ok": "Ok",
        "parts": "Parts",
        "tools": "Tools",
        "color": "Color",
        "timer": {
            "start": "START",
            "pause": "PAUSE",
            "reset": "RESET"
        }
    },
    "rights":{
        "edit":"Edit"
    }
}
