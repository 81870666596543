'use strict';

var PartView = require('./part_view');

var SwitchView = function(part) {
    PartView.call(this, part);

    this._show_port_label = true;
    this._function_digits = ['4', '3'];
    this._update_port_label();
};

var p = SwitchView.prototype = Object.create(PartView.prototype);
module.exports = SwitchView;