module.exports={
    "part_names": {
        "measuring_tip":"Острие измерительного щупа",
        "diode": "Диод",
        "z_diode": "Z-диод",
        "led": "Светодиод",
        "led_red": "Светодиод красный",
        "led_green": "Светодиод зеленый",
        "led_orange": "Светодиод оранжевый",
        "led_yellow": "Светодиод желтый",
        "dc_motor": "Двигатель постоянного тока",
        "shunt_dc_motor": "Двигатель параллельного возбуждения",
        "series_dc_motor": "Двигатель последовательного возбуждения",
        "lamp": "Лампа",
        "push_button": "Клавишный выключатель",
        "push_button_pushed": "Клавишный выключатель",
        "push_button_no_on": "Клавишный выключатель",
        "push_button_no_off": "Клавишный выключатель",
        "push_button_nc_on": "Клавишный выключатель",
        "push_button_nc_off": "Клавишный выключатель",
        "ldr_resistor": "LDR резистор",
        "mosfet_p_left": "Р-канальный MOSFET",
        "mosfet_p_right": "Р-канальный MOSFET",
        "mosfet_n_left": "N-канальный MOSFET",
        "mosfet_n_right": "N-канальный MOSFET",
        "fet_p_left": "P-канальный FET",
        "fet_p_right": "P-канальный FET",
        "fet_n_left": "N-канальный FET",
        "fet_n_right": "N-канальный FET",
        "npn": "Транзистор NPN",
        "pnp": "Транзистор PNP",
        "npn_right": "Транзистор NPN",
        "npn_left": "Транзистор NPN",
        "pnp_right": "Транзистор PNP",
        "pnp_left": "Транзистор PNP",
        "ntc": "NTC",
        "ptc": "PTC",
        "coil_2": "Катушка",
        "coil": "Катушка",
        "capacitor": "Конденсатор",
        "potentiometer": "Потенциометр",
        "car_battery": "Автомобильная аккумуляторная батарея",
        "dc_voltage_source": "Источник постоянного напряжения",
        "resistor": "Сопротивление",
        "switch_on": "Выключатель",
        "switch_off": "Выключатель",
        "switch_no_on": "Выключатель",
        "switch_no_off": "Выключатель",
        "switch_nc_on": "Выключатель",
        "switch_nc_off": "Выключатель",
        "opener_on": "Размыкающий контакт",
        "opener_off": "Размыкающий контакт",
        "fuse": "Предохранитель",
        "toggle_switch_bottom": "Реверсирующий переключатель",
        "toggle_switch_top": "Реверсирующий переключатель",
        "relais_with_diode": "Реле с нулевым вентилем",
        "relais_with_diode_closed": "Реле с нулевым вентилем",
        "contactor": "Контактор",
        "ground": "Корпус",
        "dc_voltage_terminal": "Источник постоянного напряжения",
        "wire": "Провод",
        "amperemeter": "Амперметр",
        "voltmeter": "Вольметр",
        "ohmmeter": "Омметр",
        "oscilloscope": "Осциллограф",
        "multimeter": "Мультиметр",
        "wattmeter": "Ваттметр",
        "voltage_arrow_label": "Вектор напряжения",
        "current_arrow_label": "Вектор тока",
        "minus": "Минус",
        "plus": "Плюс",
        "ampere_plus": "Ток",
        "ampere_minus": "Ток",
        "voltage_plus": "Напряжение",
        "voltage_minus": "Напряжение",
        "dot": "Точка контактирования",
        "multiselection": "Многократный выбор",
        "solar_cell": "Солнечный элемент"
    },
    "part_label": {
        "measuring_tip":"M T",
        "diode": "V",
        "z_diode": "V",
        "led": "V",
        "dc_motor": "M",
        "shunt_dc_motor": "M",
        "series_dc_motor": "M",
        "lamp": "E",
        "push_button": "S",
        "push_button_pushed": "S",
        "push_button_no_on": "S",
        "push_button_no_off": "S",
        "push_button_nc_on": "S",
        "push_button_nc_off": "S",
        "ldr_resistor": "R",
        "mosfet_p_left": "V",
        "mosfet_p_right": "V",
        "mosfet_n_left": "V",
        "mosfet_n_right": "V",
        "fet_p_left": "V",
        "fet_p_right": "V",
        "fet_n_left": "V",
        "fet_n_right": "V",
        "npn_right": "V",
        "npn_left": "V",
        "pnp_right": "V",
        "pnp_left": "V",
        "ntc": "R",
        "ptc": "R",
        "coil_2": "L",
        "coil": "L",
        "capacitor": "C",
        "dot": "",
        "potentiometer": "R",
        "car_battery": "G",
        "dc_voltage_source": "G",
        "dc_voltage_terminal": "G",
        "resistor": "R",
        "switch_on": "S",
        "switch_off": "S",
        "switch_no_on": "S",
        "switch_no_off": "S",
        "switch_nc_on": "S",
        "switch_nc_off": "S",
        "opener_on": "S",
        "opener_off": "S",
        "fuse": "F",
        "toggle_switch_bottom": "S",
        "toggle_switch_top": "S",
        "relais_with_diode": "K",
        "relais_with_diode_closed": "K",
        "contactor": "Q",
        "ground": "G",
        "voltage_arrow_label": "U",
        "current_arrow_label": "I",
        "amperemeter": "A",
        "voltmeter": "V",
        "ohmmeter": "\u03a9",
        "wattmeter": "P",
        "oscilloscope": "O",
        "solar_cell": "X"
    },
    "partCategories": {
        "resistors": "Сопротивления",
        "capacitors": "Конденсаторы и катушки",
        "electricalSwitches": "Электронные выключатели",
        "mechanicalSwitches": "Механические выключатели",
        "electroMechanicalSwitches": "Электромеханические выключатели",
        "illuminant": "Осветительные средства",
        "motors": "Двигатели",
        "powerSupply": "Подвод напряжения",
        "measurement": "Измерительные приборы",
        "tools": "Инструменты",
        "amperemeter": "Амперметр",
        "voltmeter": "Вольтметр",
        "ohmmeter": "Омметр",
        "oscilloscope": "Осциллограф",
        "multimeter": "Мультиметр",
        "wattmeter": "Ваттметр",
        "kfz": "Автомобиль"
    },
    "label": {
        "voltage_arrow_label": "Вектор напряжения",
        "current_arrow_label": "Вектор тока",
        "voltage": "Напряжение",
        "ampere": "Ток",
        "resistance": "Сопротивление",
        "inductance": "Индуктивность",
        "capacity": "Емкость",
        "z_voltage": "Пробивное напряжение",
        "min_resistance": "Минимальное сопротивление",
        "max_resistance": "Максимальное сопротивление",
        "divider": "Актуальное сопротивление",
        "light": "Освещенность",
        "temperature": "Температура",
        "load": "Нагрузка",
        "shortcircuit": "Короткое замыкание",
        "infinity": "Бесконечно",
        "contact": "Контакт",
        "nc": "NC",
        "no": "NO",
        "ratedCurrent": "Расчетная сила тока",
        "left": "Слева",
        "right": "Справа",
        "transistor_basis": "Контакт базы",
        "irradiance": "Плотность облучения"
    },
    "options": {
        "fahrenheit": false,
        "wiring_symbols": "DIN",
        "passive_sign_convention": false,
        "commaSeperator": true
    },
    "circuit_types": {
        "generic": "Генерический",
        "enclosure": "Распределительный шкаф",
        "domestic_installation": "Домовая электроустановка",
        "automobile": "Автомобиль"
    },
    "toolnames": {
        "multiselect": "Многократный выбор",
        "wire": "Инструмент для зачистки проводов",
        "voltageLabel": "Вектор напряжения",
        "currentLabel": "Вектор тока"
    },
    "ui": {
        "simulation": "Имитация",
        "editor": "Редактор",
        "toolbar": "Инструменты",
        "part_list": "Компоненты",
        "save": "Сохранить",
        "circuit": "Электрическая схема",
        "new_circuit": "Новая электрическая схема",
        "new": "Новое",
        "reset": "Восстановить",
        "quality": "Качество",
        "magnify": "Увеличить",
        "decrease": "снижение",
        "maximize": "Максимизировать",
        "minimize": "Минимизировать",
        "name": "Имя",
        "rotate": "Повернуть",
        "delete": "Стереть",
        "perspective": "Перспектива",
        "overview": "Обзор",
        "oscilloscope": "Осциллограф",
        "multimeter": "Мультиметр",
        "wattmeter": "Ваттметр",
        "toggle": "Переключить",
        "edit": "Обработать",
        "simulate": "Имитировать",
        "zoom_in": "Увеличить масштаб изображения",
        "zoom_out": "Уменьшить масштаб изображения",
        "2d_view": "2D изображение",
        "scheme": "2D изображение",
        "3d_view": "3D изображение",
        "iso": "3D изображение",
        "views": "Виды",
        "options": "Опции",
        "file": "Файл",
        "subscript": "Подстрочный индекс",
        "open": "Открыть",
        "save_as": "Сохранить как",
        "settings": "Настройки",
        "close": "Закрыть",
        "release": "Разрешение",
        "partDestroyability": "Разрушаемые детали",
        "showContactLabels": "Надписи контактов",
        "startView3d": "3D изображение при пуске",
        "openSideMenuOnStart": "Развернуто в сторону при пуске",
        "voltageEditable": "Регулируемые источники напряжения",
        "cancel": "Прекратить",
        "ok": "Ok",
        "parts": "Детали",
        "tools": "Инструменты",
        "color": "Цвет",
        "timer": {
            "start": "СТАРТ",
            "pause": "ПАУЗА",
            "reset": "СБРОС"
        }
    },
    "rights":{
        "edit":"Редактировать"
    }
}
