'use strict';

var spacing = 25;
var initialSpacing = 25;

module.exports = {
    'local_to_grid': function (x, y, round) {
        //round = round || true; // HACK: round is always true
        round = !!(typeof round == 'undefined'); // round is true if not set
        if (round) {
            return {
                'x': (Math.round(x / spacing)) * 2,
                'y': (Math.round(y / spacing)) * 2
            };
        } else {
            return {
                'x': (Math.floor(x / spacing)) * 2,
                'y': (Math.floor(y / spacing)) * 2
            };
        }
    }, 'grid_to_local': function (x, y) {
        return {
            'x': x / 2 * spacing,
            'y': y / 2 * spacing
        };
    }, 'wire_grid_to_local': function (x, y) {
        return {
            'x': x / 2 * spacing,
            'y': y / 2 * spacing
        };
    }, 'grid_to_global': function (grid_x, grid_y, dx, dy) {
        return {
            'x': (spacing / 2 * spacing) + dx,
            'y': (spacing / 2 * spacing) + dy
        };
    }, 'local_to_internal_grid': function (x, y) {
        return {
            'x': Math.floor(x / (spacing / 2)),
            'y': Math.floor(y / (spacing / 2))
        };
    }
};

// EAPP.View.helper.gridHelper.spacing
Object.defineProperty(module.exports, 'spacing', {
    get: function() { return spacing; },
    set: function(v) { spacing = v; }
});


// EAPP.View.helper.gridHelper.spacing
Object.defineProperty(module.exports, 'initialSpacing', {
    get: function() { return initialSpacing; },
    set: function(v) { initialSpacing = v; }
});