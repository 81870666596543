module.exports={
    "part_names": {
        "measuring_tip":"Picos de medición",
        "diode": "Diodo",
        "z_diode": "Diodo Zener",
        "led": "Led",
        "led_red": "Led rojo",
        "led_green": "Led verde",
        "led_orange": "Led naranja",
        "led_yellow": "Led amarillo",
        "dc_motor": "Motor de CC",
        "shunt_dc_motor": "Motor en derivación",
        "series_dc_motor": "Motor en serie",
        "lamp": "Luz",
        "push_button": "Pulsador",
        "push_button_pushed": "Pulsador",
        "push_button_no_on": "Pulsador",
        "push_button_no_off": "Pulsador",
        "push_button_nc_on": "Pulsador",
        "push_button_nc_off": "Pulsador",
        "ldr_resistor": "LDR",
        "mosfet_p_left": "MOSFET de canal P",
        "mosfet_p_right": "MOSFET de canal P",
        "mosfet_n_left": "MOSFET de canal N",
        "mosfet_n_right": "MOSFET de canal N",
        "fet_p_left": "FET de canal P",
        "fet_p_right": "FET de canal P",
        "fet_n_left": "FET de canal N",
        "fet_n_right": "FET de canal N",
        "npn": "Transistor NPN",
        "pnp": "Transistor PNP",
        "npn_right": "Transistor NPN",
        "npn_left": "Transistor NPN",
        "pnp_right": "Transistor PNP",
        "pnp_left": "Transistor PNP",
        "ntc": "NTC",
        "ptc": "PTC",
        "coil_2": "Bobina",
        "coil": "Bobina",
        "capacitor": "Condensador",
        "potentiometer": "Potenciómetro",
        "car_battery": "Batería de automóvil",
        "dc_voltage_source": "Fuente de tensión continua",
        "resistor": "Resistor",
        "switch_on": "Interruptor",
        "switch_off": "Interruptor",
        "switch_no_on": "Interruptor",
        "switch_no_off": "Interruptor",
        "switch_nc_on": "Interruptor",
        "switch_nc_off": "Interruptor",
        "opener_on": "Contacto normalmente cerrado",
        "opener_off": "Contacto normalmente cerrado",
        "fuse": "Fusible",
        "toggle_switch_bottom": "Conmutador inversor",
        "toggle_switch_top": "Conmutador inversor",
        "relais_with_diode": "Relé con diodo de carrera libre",
        "relais_with_diode_closed": "Relé con diodo de carrera libre",
        "contactor": "Contactor",
        "ground": "Masa",
        "dc_voltage_terminal": "Fuente de tensión continua",
        "wire": "Línea",
        "amperemeter": "Amperímetro",
        "voltmeter": "Voltímetro",
        "ohmmeter": "Ohmímetro",
        "oscilloscope": "Osciloscopio",
        "multimeter": "Multímetro",
        "wattmeter": "Vatímetro",
        "voltage_arrow_label": "Flecha de tensión",
        "current_arrow_label": "Flecha de corriente",
        "minus": "Menos",
        "plus": "Más",
        "ampere_plus": "Corriente",
        "ampere_minus": "Corriente",
        "voltage_plus": "Tensión",
        "voltage_minus": "Tensión",
        "dot": "Punto de contacto",
        "multiselection": "Selección múltiple",
        "solar_cell": "Celda solar"
    },
    "part_label": {
        "measuring_tip":"M T",
        "diode": "V",
        "z_diode": "V",
        "led": "V",
        "dc_motor": "M",
        "shunt_dc_motor": "M",
        "series_dc_motor": "M",
        "lamp": "E",
        "push_button": "S",
        "push_button_pushed": "S",
        "push_button_no_on": "S",
        "push_button_no_off": "S",
        "push_button_nc_on": "S",
        "push_button_nc_off": "S",
        "ldr_resistor": "R",
        "mosfet_p_left": "V",
        "mosfet_p_right": "V",
        "mosfet_n_left": "V",
        "mosfet_n_right": "V",
        "fet_p_left": "V",
        "fet_p_right": "V",
        "fet_n_left": "V",
        "fet_n_right": "V",
        "npn_right": "V",
        "npn_left": "V",
        "pnp_right": "V",
        "pnp_left": "V",
        "ntc": "R",
        "ptc": "R",
        "coil_2": "L",
        "coil": "L",
        "capacitor": "C",
        "dot": "",
        "potentiometer": "R",
        "car_battery": "G",
        "dc_voltage_source": "G",
        "dc_voltage_terminal": "G",
        "resistor": "R",
        "switch_on": "S",
        "switch_off": "S",
        "switch_no_on": "S",
        "switch_no_off": "S",
        "switch_nc_on": "S",
        "switch_nc_off": "S",
        "opener_on": "S",
        "opener_off": "S",
        "fuse": "F",
        "toggle_switch_bottom": "S",
        "toggle_switch_top": "S",
        "relais_with_diode": "K",
        "relais_with_diode_closed": "K",
        "contactor": "Q",
        "ground": "G",
        "voltage_arrow_label": "U",
        "current_arrow_label": "I",
        "amperemeter": "A",
        "voltmeter": "V",
        "ohmmeter": "\u03a9",
        "wattmeter": "P",
        "oscilloscope": "O",
        "solar_cell": "X"
    },
    "partCategories": {
        "resistors": "Resistores",
        "capacitors": "Condensadores y bobinas",
        "electricalSwitches": "Interruptores electrónicos",
        "mechanicalSwitches": "Interruptores mecánicos",
        "electroMechanicalSwitches": "Interruptores electromecánicos",
        "illuminant": "Fuente luminosa",
        "motors": "Motores",
        "powerSupply": "Alimentación de tensión",
        "measurement": "Instrumentos de medición",
        "tools": "Herramientas",
        "amperemeter": "Amperímetro",
        "voltmeter": "Voltímetro",
        "ohmmeter": "Ohmímetro",
        "oscilloscope": "Osciloscopio",
        "multimeter": "Multímetro",
        "wattmeter": "Vatímetro",
        "kfz": "Tecnología del automóvil"
    },
    "label": {
        "voltage_arrow_label": "Flecha de tensión",
        "current_arrow_label": "Flecha de corriente",
        "voltage": "Tensión",
        "ampere": "Corriente",
        "resistance": "Resistencia",
        "inductance": "Inductancia",
        "capacity": "Capacidad",
        "z_voltage": "Tensión disruptiva",
        "min_resistance": "Resistencia mínima",
        "max_resistance": "Resistencia máxima",
        "divider": "Resistencia actual",
        "light": "Iluminancia",
        "temperature": "Temperatura",
        "load": "Carga",
        "shortcircuit": "Cortocircuito",
        "infinity": "Infinito",
        "contact": "Contacto",
        "nc": "NC",
        "no": "NO",
        "ratedCurrent": "Corriente asignada",
        "left": "Izquierda",
        "right": "Derecha",
        "transistor_basis": "Contacto de la base",
        "irradiance": "Irradiancia"
    },
    "options": {
        "fahrenheit": false,
        "wiring_symbols": "DIN",
        "passive_sign_convention": false,
        "commaSeperator": true
    },
    "circuit_types": {
        "generic": "Genérico",
        "enclosure": "Armario de distribución",
        "domestic_installation": "Instalación doméstica",
        "automobile": "Tecnología del automóvil"
    },
    "toolnames": {
        "multiselect": "Selección múltiple",
        "wire": "Herramienta para cable",
        "voltageLabel": "Flecha de tensión",
        "currentLabel": "Flecha de corriente"
    },
    "ui": {
        "simulation": "Simulación",
        "editor": "Editor",
        "toolbar": "Herramientas",
        "part_list": "Componentes",
        "save": "Guardar",
        "circuit": "Circuito",
        "new_circuit": "Nuevo circuito",
        "new": "Nuevo",
        "reset": "Restablecer",
        "quality": "Calidad",
        "magnify": "Agrandar",
        "decrease": "Disminuir",
        "maximize": "Maximizar",
        "minimize": "Minimizar",
        "name": "Nombre",
        "rotate": "Girar",
        "delete": "Borrar",
        "perspective": "Perspectiva",
        "overview": "Vista general",
        "oscilloscope": "Osciloscopio",
        "multimeter": "Multímetro",
        "wattmeter": "Vatímetro",
        "toggle": "Conmutar",
        "edit": "Editar",
        "simulate": "Simular",
        "zoom_in": "Ampliar",
        "zoom_out": "Reducir",
        "2d_view": "Vista en 2D",
        "scheme": "Vista en 2D",
        "3d_view": "Vista en 3D",
        "iso": "Vista en 3D",
        "views": "Vistas",
        "options": "Opciones",
        "file": "Archivo",
        "subscript": "Subíndice",
        "open": "Abrir",
        "save_as": "Guardar como",
        "settings": "Ajustes",
        "close": "Cerrar",
        "release": "Liberación",
        "partDestroyability": "Componentes destructibles",
        "showContactLabels": "Rotulaciones de contacto",
        "startView3d": "Vista en 3D al inicio",
        "openSideMenuOnStart": "Barra lateral desplegada al inicio",
        "voltageEditable": "Fuentes de tensión ajustables",
        "cancel": "Cancelar",
        "ok": "Ok",
        "parts": "Componentes",
        "tools": "Herramientas",
        "color": "Color",
        "timer": {
            "start": "START",
            "pause": "PAUSE",
            "reset": "RESET"
        }
    },
    "rights":{
        "edit":"Editar"
    }
}
