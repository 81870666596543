'use strict';

var PartView = require('./part_view'),
    part_sizes = require('../helper/part_sizes'),
    grid_helper = require('../helper/grid_helper');

var MultimeterView = function(part) {
    PartView.call(this, part);
    this.removeChild(this._text_label);
};

var p = MultimeterView.prototype = Object.create(PartView.prototype);
module.exports = MultimeterView;

p.draw = function() {
    PartView.prototype.draw.call(this);

    var text_cont = new GOWN.Control();
    text_cont.rotation = this.orientation;
    this.addChild(text_cont);
    var text = '';
    if (this._type == 'amperemeter') {
        text = 'A';
    } else if (this._type == 'voltmeter') {
        text = 'V';
    }
    var elm_text = new PIXI.Text(text, {'fontWeight': 'bold', 'fontSize': 32, 'fontFamily': 'Arial', 'fill': 0x000000});
    var size = part_sizes[this._type]['scheme'].size;
    var width = text_cont.width = grid_helper.spacing * size.x / 2;
    var height = text_cont.height = grid_helper.spacing * size.y / 2;
    elm_text.anchor = {'x': 0.5, 'y': 0.5};
    elm_text.x = width / 2;
    elm_text.y = height / 2;
    elm_text.rotation = -this.orientation;

    text_cont.addChild(elm_text);
};

p.draw_textlabel = function() {};