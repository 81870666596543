'use strict';

/**
 * A label that can be attached to any kind of part including wires
 * @class
 * @abstract
 * @extends GOWN.Control
 * @constructor
 */
var Label = function () {
    GOWN.Control.call(this);

    this.id = '_label';
    this.type = this.type || '_label';
    this.name = 'Label';
};

var p = Label.prototype = Object.create(GOWN.Control.prototype);
module.exports = Label;

var oHeight = Object.getOwnPropertyDescriptor(PIXI.Container.prototype, 'height');
var oWidth = Object.getOwnPropertyDescriptor(PIXI.Container.prototype, 'width');

// We get our original container width/height functions
// Which auto calculate the size depending on the bounds
// TODO Why the hell does a GOWN.Control overwrite this
Object.defineProperties(p, {
    'width':{
        get: oWidth.get,
        set: oWidth.set
    },
    'height':  {
        get: oHeight.get,
        set: oHeight.set
    }
});
