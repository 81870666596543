module.exports = {
    'wire': require('./wire_view'),
    'measuring_tip': require('./part_view'),
    'diode': require('./part_view'),
    'z_diode': require('./part_view'),
    'led': require('./part_view'),
    'mosfet_p_left': require('./part_view'),
    'mosfet_p_right': require('./part_view'),
    'mosfet_n_left': require('./part_view'),
    'mosfet_n_right': require('./part_view'),
    'fet_p_left': require('./part_view'),
    'fet_p_right': require('./part_view'),
    'fet_n_left': require('./part_view'),
    'fet_n_right': require('./part_view'),
    'npn_right': require('./part_view'),
    'npn_left': require('./part_view'),
    'pnp_right': require('./part_view'),
    'pnp_left': require('./part_view'),
    'coil': require('./part_view'),
    'coil_2': require('./part_view'),
    'capacitor': require('./part_view'),
    'potentiometer': require('./part_view'),
    'car_battery': require('./part_view'),
    'dc_voltage_source': require('./part_view'),
    'dc_voltage_terminal': require('./dc_voltage_terminal_view'),
    'ground': require('./part_view'),
    'dc_motor': require('./motor_view'),
    'shunt_dc_motor': require('./part_view'),
    'series_dc_motor': require('./part_view'),
    'switch_off': require('./switch_view'),
    'switch_on': require('./switch_view'),
    'push_button': require('./part_view'),
    'ptc': require('./part_view'),
    'relais_with_diode': require('./part_view'),
    'toggle_switch_top': require('./toggle_switch_view'),
    'toggle_switch_bottom': require('./toggle_switch_view'),
    'lamp': require('./part_view'),
    'ldr_resistor': require('./part_view'),
    'ntc': require('./part_view'),
    'resistor': require('./part_view'),
    'amperemeter': require('./multimeter_view'),
    'voltmeter': require('./multimeter_view'),
    'solar_cell': require('./part_view')
};