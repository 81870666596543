'use strict';
var grid_helper = require('../helper/grid_helper');

var WireView = function(id, point, grid_to_local, current_mode) {
    GOWN.Control.call(this);
    this.current_mode = current_mode;
    this._points = [];
    this._id = id;
    this._color = 0x000000;
    if(point.hasOwnProperty('length')) {
        this._points = point;
    } else {
        this.points.push(point);
    }
    if(typeof grid_to_local == 'undefined') {
        this._grid_to_local = grid_helper.wire_grid_to_local;
    } else {
        this._grid_to_local =  grid_to_local;
    }
    this.x = this.y = 0;

    this._wire = new PIXI.Graphics();
};

var p = WireView.prototype = Object.create(GOWN.Control.prototype);
module.exports = WireView;

p.draw = function() {
    var i, local;
    var color = this._color;
    //this.removeAllChildren();

    this._wire.clear();
    this._wire.x = this._wire.y = 0;
    this._wire.lineStyle(2, color, 1);

    var data = this.parent.parent.circuit.data;
    for (i = 0; i < this._points.length; i++) {
        var p = this._points[i];
        var dot_id = p.x + ',' + p.y;
        if (dot_id in data._dots) {
            var dot = data._dots[dot_id];
            for (var j = 0; j < dot.connections.length; j++) {
                var con = dot.connections[j];
                if ((!isNaN(parseInt(con.id)) && con.id != this.id)
                    || (this.parent.parent._parts[con.id]
                    && this.parent.parent._parts[con.id]._type == 'dc_voltage_terminal')) {
                    local = this._grid_to_local(p.x, p.y);
                    this._wire.beginFill(color);
                    this._wire.drawCircle(local.x, local.y, 3);
                    break;
                }
            }
        }
    }

    //Draw Wire
    for(i=0; i<this._points.length-1; i++) {
        var point = this._points[i];
        local = this._grid_to_local(point.x, point.y);
        var next = this._points[i+1];
        var n_local = this._grid_to_local(next.x, next.y);
        this._wire.moveTo(local.x, local.y).lineTo(n_local.x, n_local.y).es();
    }
    this.addChild(this._wire);
};

Object.defineProperty(p, 'id', {
    get: function() {
        return this._id;
    }
});

Object.defineProperty(p, 'points', {
    get: function() {
        return this._points;
    }
});
