module.exports={
    "part_names": {
        "measuring_tip":"Messspitze",
        "diode": "Diode",
        "z_diode": "Z-Diode",
        "led": "LED",
        "led_red": "LED Rot",
        "led_green": "LED Grün",
        "led_orange": "LED Orange",
        "led_yellow": "LED Gelb",
        "dc_motor": "DC Motor",
        "shunt_dc_motor": "Nebenschluss Motor",
        "series_dc_motor": "Reihenschluss Motor",
        "lamp": "Lampe",
        "push_button": "Taster",
        "push_button_pushed": "Taster",
        "push_button_no_on": "Taster",
        "push_button_no_off": "Taster",
        "push_button_nc_on": "Taster",
        "push_button_nc_off": "Taster",
        "ldr_resistor": "LDR",
        "mosfet_p_left": "P-Kanal MOSFET",
        "mosfet_p_right": "P-Kanal MOSFET",
        "mosfet_n_left": "N-Kanal MOSFET",
        "mosfet_n_right": "N-Kanal MOSFET",
        "fet_p_left": "P-Kanal FET",
        "fet_p_right": "P-Kanal FET",
        "fet_n_left": "N-Kanal FET",
        "fet_n_right": "N-Kanal FET",
        "npn": "NPN Transistor",
        "pnp": "PNP Transistor",
        "npn_right": "NPN Transistor",
        "npn_left": "NPN Transistor",
        "pnp_right": "PNP Transistor",
        "pnp_left": "PNP Transistor",
        "ntc": "NTC",
        "ptc": "PTC",
        "coil_2": "Spule",
        "coil": "Spule",
        "capacitor": "Kondensator",
        "potentiometer": "Potentiometer",
        "car_battery": "Autobatterie",
        "dc_voltage_source": "Gleichspannungsquelle",
        "resistor": "Widerstand",
        "switch_on": "Schalter",
        "switch_off": "Schalter",
        "switch_no_on": "Schalter",
        "switch_no_off": "Schalter",
        "switch_nc_on": "Schalter",
        "switch_nc_off": "Schalter",
        "opener_on": "Öffner",
        "opener_off": "Öffner",
        "fuse": "Sicherung",
        "toggle_switch_bottom": "Wechselschalter",
        "toggle_switch_top": "Wechselschalter",
        "relais_with_diode": "Relais mit Freilaufdiode",
        "relais_with_diode_closed": "Relais mit Freilaufdiode",
        "contactor": "Schütz",
        "ground": "Masse",
        "dc_voltage_terminal": "Gleichspannungsquelle",
        "wire": "Leitung",
        "amperemeter": "Amperemeter",
        "voltmeter": "Voltmeter",
        "ohmmeter": "Ohmmeter",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimeter",
        "wattmeter": "Wattmeter",
        "voltage_arrow_label": "Spannungspfeil",
        "current_arrow_label": "Strompfeil",
        "minus": "Minus",
        "plus": "Plus",
        "ampere_plus": "Strom",
        "ampere_minus": "Strom",
        "voltage_plus": "Spannung",
        "voltage_minus": "Spannung",
        "dot": "Kontaktpunkt",
        "multiselection": "Mehrfachauswahl",
        "solar_cell": "Solarzelle"
    },
    "part_label": {
        "measuring_tip":"M T",
        "diode": "V",
        "z_diode": "V",
        "led": "V",
        "dc_motor": "M",
        "shunt_dc_motor": "M",
        "series_dc_motor": "M",
        "lamp": "E",
        "push_button": "S",
        "push_button_pushed": "S",
        "push_button_no_on": "S",
        "push_button_no_off": "S",
        "push_button_nc_on": "S",
        "push_button_nc_off": "S",
        "ldr_resistor": "R",
        "mosfet_p_left": "V",
        "mosfet_p_right": "V",
        "mosfet_n_left": "V",
        "mosfet_n_right": "V",
        "fet_p_left": "V",
        "fet_p_right": "V",
        "fet_n_left": "V",
        "fet_n_right": "V",
        "npn_right": "V",
        "npn_left": "V",
        "pnp_right": "V",
        "pnp_left": "V",
        "ntc": "R",
        "ptc": "R",
        "coil_2": "L",
        "coil": "L",
        "capacitor": "C",
        "dot": "",
        "potentiometer": "R",
        "car_battery": "G",
        "dc_voltage_source": "G",
        "dc_voltage_terminal": "G",
        "resistor": "R",
        "switch_on": "S",
        "switch_off": "S",
        "switch_no_on": "S",
        "switch_no_off": "S",
        "switch_nc_on": "S",
        "switch_nc_off": "S",
        "opener_on": "S",
        "opener_off": "S",
        "fuse": "F",
        "toggle_switch_bottom": "S",
        "toggle_switch_top": "S",
        "relais_with_diode": "K",
        "relais_with_diode_closed": "K",
        "contactor": "Q",
        "ground": "G",
        "voltage_arrow_label": "U",
        "current_arrow_label": "I",
        "amperemeter": "A",
        "voltmeter": "V",
        "ohmmeter": "\u03a9",
        "wattmeter": "P",
        "oscilloscope": "O",
        "solar_cell": "X"
    },
    "partCategories": {
        "resistors": "Widerstände",
        "capacitors": "Kondensatoren und Spulen",
        "electricalSwitches": "Elektronische Schalter",
        "mechanicalSwitches": "Mechanische Schalter",
        "electroMechanicalSwitches": "Elektromechanische Schalter",
        "illuminant": "Leuchtmittel",
        "motors": "Motoren",
        "powerSupply": "Spannungsversorgung",
        "measurement": "Messgeräte",
        "tools": "Werkzeuge",
        "amperemeter": "Amperemeter",
        "voltmeter": "Voltmeter",
        "ohmmeter": "Ohmmeter",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimeter",
        "wattmeter": "Wattmeter",
        "kfz": "KFZ"
    },
    "label": {
        "voltage_arrow_label": "Spannungspfeil",
        "current_arrow_label": "Strompfeil",
        "voltage": "Spannung",
        "ampere": "Strom",
        "resistance": "Widerstand",
        "inductance": "Induktivität",
        "capacity": "Kapazität",
        "z_voltage": "Durchbruchspannung",
        "min_resistance": "Mindestwiderstand",
        "max_resistance": "Höchstwiderstand",
        "divider": "Aktueller Widerstand",
        "light": "Beleuchtungsstärke",
        "temperature": "Temperatur",
        "load": "Last",
        "shortcircuit": "Kurzschluss",
        "infinity": "Unendlich",
        "contact": "Kontakt",
        "nc": "NC",
        "no": "NO",
        "ratedCurrent": "Bemessungsstromstärke",
        "left": "Links",
        "right": "Rechts",
        "transistor_basis": "Basiskontakt",
        "irradiance": "Sonneneinstrahlung"
    },
    "options": {
        "fahrenheit": false,
        "wiring_symbols": "DIN",
        "passive_sign_convention": false,
        "commaSeperator": true
    },
    "circuit_types": {
        "generic": "Generisch",
        "enclosure": "Schaltschrank",
        "domestic_installation": "Hausinstallation",
        "automobile": "KFZ"
    },
    "toolnames": {
        "multiselect": "Mehrfachauswahl",
        "wire": "Kabelwerkzeug",
        "voltageLabel": "Spannungspfeil",
        "currentLabel": "Strompfeil"
    },
    "ui": {
        "simulation": "Simulation",
        "editor": "Editor",
        "toolbar": "Werkzeuge",
        "part_list": "Komponenten",
        "save": "Speichern",
        "circuit": "Schaltkreis",
        "new_circuit": "Neuer Schaltkreis",
        "new": "Neu",
        "reset": "Zurücksetzen",
        "quality": "Qualität",
        "magnify": "Vergrößern",
        "decrease": "Verkleinern",
        "maximize": "Maximieren",
        "minimize": "Minimieren",
        "name": "Name",
        "rotate": "Drehen",
        "delete": "Löschen",
        "perspective": "Perspektive",
        "overview": "Übersicht",
        "oscilloscope": "Oszilloskop",
        "multimeter": "Multimeter",
        "wattmeter": "Wattmeter",
        "toggle": "Schalten",
        "edit": "Bearbeiten",
        "simulate": "Simulieren",
        "zoom_in": "Zoom-in",
        "zoom_out": "Zoom-out",
        "2d_view": "2D Ansicht",
        "scheme": "2D Ansicht",
        "3d_view": "3D Ansicht",
        "iso": "3D Ansicht",
        "views": "Ansichten",
        "options": "Optionen",
        "file": "Datei",
        "subscript": "Tiefgestellt",
        "open": "Öffnen",
        "save_as": "Speichern unter",
        "settings": "Einstellungen",
        "close": "Schließen",
        "release": "Freigabe",
        "partDestroyability": "Bauteile zerstörbar",
        "showContactLabels": "Kontaktbeschriftungen",
        "startView3d": "3D-Ansicht beim Start",
        "openSideMenuOnStart": "Sidebar ausgeklappt beim Start",
        "voltageEditable": "Spannungsquellen einstellbar",
        "cancel": "Abbrechen",
        "ok": "Ok",
        "parts": "Bauteile",
        "tools": "Werkzeuge",
        "color": "Farbe",
        "timer": {
            "start": "START",
            "pause": "PAUSE",
            "reset": "RESET"
        }
    },
    "rights":{
        "edit":"Editieren"
    }
}
