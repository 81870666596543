var grid_helper = require('./grid_helper');

/**
 * This creates a trace of all grid coordinates passed when traversing
 * from one point to another
 *
 * All imput coordinates should be grid-coordiantes!!!
 *
 * @param start {PIXI.Point} The start point of the trace
 * @param end   {PIXI.Point} The end point of the trace
 */
var trace_coords = function (start, end) {
    // copy input
    start = {
        'x': start.x,
        'y': start.y
    };

    end = {
        'x': end.x,
        'y': end.y
    };

    // create vectors and lengths
    var dir_vector = create_vector(start, end);
    var whole_length = get_vector_length(dir_vector);
    var uv = get_unit_vector(dir_vector);
    var unit = get_vector_length(uv) * 2;

    // loop through every coord and snap dat grid!
    var result = [];
    for (var i = 0; i < whole_length; i += unit) {
        var local = grid_helper.grid_to_local(start.x, start.y);
        var grid = grid_helper.local_to_grid(local.x, local.y);
        result.push(grid);

        start.x += uv.x * 2;
        start.y += uv.y * 2;
    }

    return result;
};


/**
 * Creates a vector from two points
 *
 * @param start {PIXI.Point} The start point of the vector
 * @param end   {PIXI.Point} The end point of the vector
 * @returns {{x: number, y: number}}
 */
var create_vector = function (start, end) {
    var x = end.x - start.x;
    var y = end.y - start.y;

    return {
        'x': x,
        'y': y
    };
};


/**
 * Calculates the length of the given vector
 *
 * @param vec
 * @returns {number}
 */
var get_vector_length = function (vec) {
    return Math.sqrt((vec.x * vec.x) + (vec.y * vec.y));
};


/**
 * Calculates the unit vector of the given vector
 *
 * @param vec
 * @returns {{x: number, y: number}}
 */
var get_unit_vector = function (vec) {
    var length = get_vector_length(vec);
    vec.x /= length;
    vec.y /= length;

    return {
        'x': vec.x,
        'y': vec.y
    };
};


module.exports = {
    'trace_coords': trace_coords
};