module.exports = {
    'Arrow': require('./arrow'),
    'ArrowHead': require('./arrow_head'),
    'ArrowLabel': require('./arrow_label'),
    'CurrentArrowLabel': require('./current_arrow_label'),
    'Label': require('./label'),
    'PartLabel': require('./part_label'),
    'TextLabel': require('./text_label'),
    'VoltageArrowLabel': require('./voltage_arrow_label')
};
