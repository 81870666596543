'use strict';

var Label = require('./label');

/**
 * A label to display the given text
 * @class TextLabel
 * @extends Label
 * @param  {String} text
 * @constructor
 */
var TextLabel = function (text) {
    Label.call(this);

    this.id = '_text' + this.id;

    this._text = text;
    this._color = 0x000000;

    this._updateText();
};

var p = TextLabel.prototype = Object.create(Label.prototype);
module.exports = TextLabel;


/**
 * Updates the displayed text to the given one
 */
p._updateText = function () {
    this.removeChildren();
    var displayText = this._displayText = new PIXI.Text(this.text, {'fontWeight': 'bold', 'fontSize': 24, 'fontFamily': 'Arial', 'fill': this.color});
    displayText.pivot.x = displayText.width / 2;
    displayText.pivot.y = displayText.height;
    this.addChild(displayText);
};

/**
 * The text the label will display
 * Setter automatically calls _updateText
 * @property text
 */
Object.defineProperty(p, 'text', {
    get: function () {
        return this._text;
    },
    set: function (newvalue) {
        this._text = newvalue;
        this._updateText();
    }
});

/**
 * The color the label's text will have
 * Setter automatically calls _updateText
 * @property color
 */
Object.defineProperty(p, 'color', {
    get: function () {
        return this._color;
    },
    set: function (newvalue) {
        this._color = newvalue;
        this._updateText();
    }
});