var draw_IEC = function (graphics, type) {
    var line_width = re['line_width'];
    var color = re['color'];
    var background = re['background'];
    var alpha = re['alpha'];

    switch (type) {
        case 'ground':
            graphics.ss(line_width).s(color).p('APoAAIAAXc').es().ss(line_width).s(color).p('AAAXcIfQAA').es().ss(line_width).s(color).p('EAH0AnEIPoAA').es().ss(line_width).s(color).p('AD6fQIXcAA').es();
            break;
        case 'led':
            graphics.f(0xffffff).p("EAPoA+WIfGvyI/GvoIAAfa").cp().ef().beginFill(background, alpha).ss(line_width).s(color).p("EAPoA+qIfGvyI/GvoIAAfa").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAAfQ").es().ss(line_width).s(color).p("EA+WAu4Mg+WAAA").es().ss(line_width).s(color).p("EAfuAhc").es().f(color).p("AVkTOIo6leIFAJYIBkhkIJYJ2IAygyIpYp2IBkhk").cp().ef().f(color).p("AcwMqIBuhuIpElUIFKJOIBkhkIImIwIAygyIowom").cp().ef();
            break;
        case 'diode':
            graphics.f(background).ss(line_width).s(color).p("APefQIfQvoI/QvoIAAfQ").cp().ef().es().ss(line_width).s(color).p("EAuuAAAIAAfQ").es().ss(line_width).s(color).p("EA+gAPoMg+gAAA").es();
            break;
        case 'z_diode':
            graphics.f(background).ss(line_width).s(color).p("APofQIfGvoI/GveIAAfG").cp().ef().es().ss(line_width).s(color).p("EAuuAAAIAAfQInqAA").es().ss(line_width).s(color).p("EA+gAPoMg+gAAA").es();
            break;
        case 'dc_motor':
            graphics.f(background).ss(line_width).s(color).p("EBtOAuuYAAZ808U85yAAY58AA0808AA58YAA5yU808Z8AAYZyAAU8U8AAZy").cp().ef().es().ss(line_width).s(color).p("APoPo").es().ss(line_width).s(color).p("EBKOAPo").es().f(background).ss(18).s(color).p("EBtYAPo").ef().es().ss(line_width).s(color).p("EAPoAuuIvoAA").es().ss(line_width).s(color).p("EBtOAuuIPyAA").es().f(color).p("EBGKAtKYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'shunt_dc_motor':
            graphics.f(background).ss(line_width).s(color).p("ECMeBN+YAAZ808U85yAAY58AA0808AA58YAA5yU81GZ8AAYZyAAU8VGAAZy").cp().ef().es().ss(line_width).s(color).p("EAu4Au4").es().ss(line_width).s(color).p("EBpeAu4").es().f(background).ss(18).s(color).p("ECMoAu4").ef().es().ss(line_width).s(color).p("EAu4BN+IvoAKMAAAhOI").es().ss(line_width).s(color).p("ECMeBN+IPyAKMAAAhOI").es().ss(line_width).s(color).p("EBOICMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EBdwCMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EBtYCMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EB9ACMeYgKkijIjIksgKYkiAAjIDSgKEi").es().ss(line_width).s(color).p("EBN+BdwIfaAA").es().ss(line_width).s(color).p("EBZ2BlkIr4AA").es().ss(line_width).s(color).p("EBhqBlkILuAA").es().ss(line_width).s(color).p("EAfQBOIMAAAA+gIfQAA").es().ss(line_width).s(color).p("ECcQBOIMAAAA+gI/QAA").es().f(color).p("EBlaBIgYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'series_dc_motor':
            graphics.f(background).ss(line_width).s(color).p("ECMeBN+YAAZ808U85yAAY58AA0808AA58YAA5yU81GZ8AAYZyAAU8VGAAZy").cp().ef().es().ss(line_width).s(color).p("EAu4Au4").es().ss(line_width).s(color).p("EBpeAu4").es().f(background).ss(18).s(color).p("ECMoAu4").ef().es().ss(line_width).s(color).p("EAu4BN+IvoAKMAAAhOI").es().ss(line_width).s(color).p("ECMeBN+IPyAKIAA/Q").es().ss(line_width).s(color).p("ECcGAfQYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("ECcGAPoYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("ECcGAAAYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("EBN+BdwIfaAA").es().ss(line_width).s(color).p("EBZ2BlkIr4AA").es().ss(line_width).s(color).p("EBhqBlkILuAA").es().f(color).p("EBlaBIgYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'lamp':
            graphics.f(0xffffff).p("APePoYAAImHCHCIwAAYImAAHCnCAAomYAAownCm4omAAYowAAnCG4AAIw").cp().ef().beginFill(background, alpha).ss(line_width).s(color).p("APePoYAAImHCHCIwAAYImAAHCnCAAomYAAownCm4omAAYowAAnCG4AAIw").cp().ef().es().ss(line_width).s(color).p("AUKEiIWCWM").es().ss(line_width).s(color).p("EAqMAEiI2CWM").es().ss(line_width).s(color).p("AAAPoIPogK").es().ss(line_width).s(color).p("EAu4APeIPoAA").es();
            break;
        case 'push_button':
            graphics.ss(line_width).s(color).p("EA+gA+gIfQAA").es().ss(line_width).s(color).p("EAAAA+gIfQAAMAjKgRq").es().ss(line_width).s(color).p("EAnEAPAIAAnMIPoAAIAAHM").es().ss(line_width).s(color).p("EAu4AnEIAAPo").es().ss(line_width).s(color).p("EAu4ATiIAAPo").es().ss(line_width).s(color).p("EAu4AH0IAAH0").es();
            break;
        case 'push_button_pushed':
            graphics.ss(line_width).s(color).p("EBdwA+gI/QAAIAAPo").es().ss(line_width).s(color).p("EAAAA+gIfQAAMAjKALu").es().ss(line_width).s(color).p("EAnEAauIAAnMIPoAAIAAHM").es().ss(line_width).s(color).p("EAu4AyyIAAQ4").es().ss(line_width).s(color).p("EAu4AfQIAAPo").es().ss(line_width).s(color).p("EAu4ATiIAAH0").es().ss(line_width).s(color).p("EAfGBRkILuAAIAACCIH+j6IAAAAIn+j6IAACgIruAA").es();
            break;
        case 'push_button_no_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAQ4').es().ss(line_width).s(color).p('EAu4AfQMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAH0').es().ss(line_width).s(color).p('EAbMBMGMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().cp()
            break;
        case 'push_button_no_off':
            graphics.ss(line_width).s(color).p('EA+gA+gMAfQAAA').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMgRm').es().ss(line_width).s(color).p('EAnEAPFMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AnEMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAPo').es().ss(line_width).s(color).p('EAu4AH0MAAAAH0').es().cp()
            break;
        case 'push_button_nc_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAfQATi').es().ss(line_width).s(color).p('EAnEAetMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4A2sMAAAARW').es().ss(line_width).s(color).p('EAu4AjKMAAAAPo').es().ss(line_width).s(color).p('EAu4AXcMAAAAH0').es().ss(line_width).s(color).p('EAbMBQAMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().cp()
            break;
        case 'push_button_nc_off':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAQ4').es().ss(line_width).s(color).p('EAu4AfQMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAH0').es().cp()
            break;
        case 'switch_on':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKALu").es().ss(line_width).s(color).p("EAmSAJiILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'switch_off':
            graphics.ss(line_width).s(color).p("EA+gAPoIfQAA").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKgRf").es();
            break;
        case 'switch_no_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAbMBMGMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().ss(line_width).s(color).p('EAu4AyyMAAAAH0').es().ss(line_width).s(color).p('EAu4AjKMAAAAH0').es().ss(line_width).s(color).p('EAu4ATiMAAAALu').es().ss(line_width).s(color).p('EAu4Aq+MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAD6').es().cp();
            break;
        case 'switch_no_off':
            graphics.ss(line_width).s(color).p('EA+gA+gMAfQAAA').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMgRm').es().ss(line_width).s(color).p('EAnEAPFMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AnEMAAAAH0').es().ss(line_width).s(color).p('EAu4AXcMAAAAH0').es().ss(line_width).s(color).p('EAu4AH0MAAAALu').es().ss(line_width).s(color).p('EAu4AfQMgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4AyyMAAAAD6').es().cp();
            break;
        case 'switch_nc_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAfQATi').es().ss(line_width).s(color).p('EAnEAetMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAbMBQAMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().ss(line_width).s(color).p('EAu4A2sMAAAAH0').es().ss(line_width).s(color).p('EAu4AnEMAAAAH0').es().ss(line_width).s(color).p('EAu4AXcMAAAALu').es().ss(line_width).s(color).p('EAu4Au4MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAKA').es().cp()
            break;
        case 'switch_nc_off':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAH0').es().ss(line_width).s(color).p('EAu4AjKMAAAAH0').es().ss(line_width).s(color).p('EAu4ATiMAAAALu').es().ss(line_width).s(color).p('EAu4Aq+MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAD6').es().cp();
            break;
        case 'opener_on':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAIfQTi").es().ss(line_width).s(color).p("AfGJsILuAAIAACCIH+j6IAAAAIn+j6IAACgIruAA").es();
            break;
        case 'opener_off':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKALu").es();
            break;
        case 'toggle_switch_bottom':
            graphics.ss(line_width).s(color).p("EA+gAPoIAAvoIfQAA").es().ss(line_width).s(color).p("EBdwAfQI/QAAIAAPo").es().ss(line_width).s(color).p("AAAfQIfQAAMAjKALu").es().ss(line_width).s(color).p("EAmSAZKILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'toggle_switch_top':
            graphics.ss(line_width).s(color).p("AAAfQIXcAA").es().ss(line_width).s(color).p("EA+gAfGIfQAA").es().ss(line_width).s(color).p("AAAfGIfQAAMAjKgTY").es().ss(line_width).s(color).p("EA+gAPoIAAvoIfQAA").es();
            break;
        case 'ldr_resistor':
            graphics.ss(line_width).s(color).p("EAMgAu4IsgAA").es().ss(line_width).s(color).p("EA+gAuuIs0AA").es().ss(line_width).s(color).p("EAfGAns").es().f(background).ss(line_width).s(color).p("EA2sAnEMgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es().f(color).p("EA3UAUeIgygyIomIwIhuhuIlUJEIJOlKIhkhkIIwom").cp().ef().f(color).p("EAnsAVaIhkhkIIwomIgygyIomIwIhuhuIlUJEIJOlK").cp().ef();
            break;
        case 'resistor':
            graphics.ss(line_width).s(color).p("EA+gAPoItIgKIi+E2ImQpiImGJiImGpiImQJiImGpiIjIEsIsgAK").es();
            break;
        case 'potentiometer':
            graphics.ss(line_width).s(color).p("ANcfQItcAA").es().ss(line_width).s(color).p("EA+gAfQItwAA").es().f(background).ss(line_width).s(color).p("EA2sAXcMgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es().f(color).p("AeyAAIAANIIiWAAIC0KUICqqUIiMAAIAAtIIg8AA").cp().ef().ss(line_width).s(color).p("AeyAAIfuAA").es();
            break;
        case 'ntc':
            graphics.ss(line_width).s(color).p("AH0PoIn0AA").es().ss(line_width).s(color).p("EA+gAPoIn0AA").es().f(background).ss(line_width).s(color).p("AH0XcMAu4AAAIAAvoMgu4AAAIAAPo").cp().ef().es().f(color).p("EA6mALuIAAvn").ef().f(color).p("EA8eADIIh4nBIiCHBIBkAAIAAImIA8AAIAAomIBaAA").cp().ef().f(color).p("EBBoAEiIhaAAIAAobIg8AAIAAIbIhkAAIB4HMICCnM").cp().ef().ss(line_width).s(color).p("AFodOIKKAAIeo7g").es().ss(8).s(color).p("AbgfGYAAAACghQg8C+YgUAyBQCMCMhkYA8goB4n+jcAAYiWAAhGEYGugU").es();
            break;
        case 'ptc':
            graphics.ss(line_width).s(color).p('EAH0APoMgH0AAA').es().ss(line_width).s(color).p('EA+gAPoMgH0AAA').es().f(background).ss(line_width).s(color).p('EAH0AXcMAu4AAAMAAAgPoMgu4AAAMAAAAPo').es().ef().ss(line_width).s(color).p('EAFtAdUMAKOgABMAekgbe').es().f(0x000000).p('EA6mALuMAAAgPo').ef().f(0x000000).p('EA8gADNMgB6gHHMgB6AHHMABcAAAMAAAAIhMAA8AAAMAAAgIhMABcAAA').ef().f(0x000000).p('EA92ADNMABcgAAMAAAAIhMAA8gAAMgAAgIhMABcgAAMgB6gHHMgB6AHH').ef().ss(8).s(color).p('EAbjAfOcAAAAAAACggBWgA5ADBcgAPAAzABHACMACLgBncAA8gAnAB8gH5gDegACcgCXgABgA/AEVAGogAX').es().cp()
            break;
        case 'mosfet_p_left':
            graphics.ss(line_width).s(color).p("AfQPoIfQAA").es().ss(line_width).s(color).p("AfQfQIbWAA").es().ss(line_width).s(color).p("EAfQAu4IfQAA").es().ss(line_width).s(color).p("AcIPoIAAfQIAAAAI8IAA").es().f(color).p("Afae8IAAAUIAAAKIAAAU").ef().ss(line_width).s(color).p("AfQH0IAAPo").es().ss(line_width).s(color).p("EAfQAnEIAAPo").es().ss(line_width).s(color).p("AfQbWIAAH0").es().ss(line_width).s(color).p("EAnEAjKIH0kEIn0jw").es();
            break;
        case 'mosfet_p_right':
            graphics.ss(line_width).s(color).p("AfQPoIfQAA").es().ss(line_width).s(color).p("AfQfQIbWAA").es().ss(line_width).s(color).p("EAfQAu4IfQAA").es().ss(line_width).s(color).p("AcIPoIAAfQIAAAAI8IAA").es().f(color).p("Afae8IAAAUIAAAKIAAAU").ef().ss(line_width).s(color).p("AfQH0IAAPo").es().ss(line_width).s(color).p("EAfQAnEIAAPo").es().ss(line_width).s(color).p("AfQbWIAAH0").es().ss(line_width).s(color).p("EAnEAjKIH0kEIn0jw").es();
            break;
        case 'mosfet_n_left':
            graphics.ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('AfQfQIbWAA').es().ss(line_width).s(color).p('EAfQAu4IfQAA').es().ss(line_width).s(color).p('AcIPoIAAfQIAAAAI8IAA').es().f(color).p('Afae8IAAAUIAAAKIAAAU').ef().ss(line_width).s(color).p('AfQH0IAAPo').es().ss(line_width).s(color).p('EAfQAnEIAAPo').es().ss(line_width).s(color).p('AfQbWIAAH0').es().ss(line_width).s(color).p('EAu4AbWIn0D6IH0D6').es();
            break;
        case 'mosfet_n_right':
            graphics.ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('AfQfQIbWAA').es().ss(line_width).s(color).p('EAfQAu4IfQAA').es().ss(line_width).s(color).p('AcIPoIAAfQIAAAAI8IAA').es().f(color).p('Afae8IAAAUIAAAKIAAAU').ef().ss(line_width).s(color).p('AfQH0IAAPo').es().ss(line_width).s(color).p('EAfQAnEIAAPo').es().ss(line_width).s(color).p('AfQbWIAAH0').es().ss(line_width).s(color).p('EAu4AbWIn0D6IH0D6').es();
            break;
        case 'fet_p_left':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAPoAyyIH0j6In0j6').es();
            break;
        case 'fet_p_right':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAPoAyyIH0j6In0j6').es();
            break;
        case 'fet_n_left':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAXcAq0In0D6IH0D6').es();
            break;
        case 'fet_n_right':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAXcAq0In0D6IH0D6').es();
            break;
        case 'npn_right':
            graphics.ss(line_width).s(color).p('EAfQAu4MgAAgfQ').es().ss(line_width).s(color).p('EAfKAfaMAfWgAA').es().ss(line_width).s(color).p('EAAAA+gMAfCgXc').es().ss(line_width).s(color).p('EAAAAAAMAfCAXc').es().ss(line_width).s(color).p('EAYbAwaMgIdACOMADTgIG').es().cp()
            break;
        case 'npn_left':
            graphics.ss(line_width).s(color).p('EAfQAu4MAAAgfQ').es().ss(line_width).s(color).p('EAfWAfaMgfWgAA').es().ss(line_width).s(color).p('EA+gA+gMgfCgXc').es().ss(line_width).s(color).p('EA+gAAAMgfCAXc').es().ss(line_width).s(color).p('EAmFAwaMAIdACOMgDTgIG').es().cp()
            break;
        case 'pnp_right':
            graphics.ss(line_width).s(color).p('EAfQAPoMgAAAfQ').es().ss(line_width).s(color).p('EAfKAfGMAfqAAA').es().ss(line_width).s(color).p('EgAUAAAMAfVAXc').es().ss(line_width).s(color).p('EgAUA+gMAfVgXc').es().ss(line_width).s(color).p('EAOGAvOMAIpgCOMgDXAIG').es().cp()
            break;
        case 'pnp_left':
            graphics.ss(line_width).s(color).p('EAfQAPoMAAAAfQ').es().ss(line_width).s(color).p('EAfWAfGMgfWAAA').es().ss(line_width).s(color).p('EA+gAAAMgfCAXc').es().ss(line_width).s(color).p('EA+gA+gMgfCgXc').es().ss(6.04013442993164).s(color).p('EAwPAvOMgIkgCOMADVAIG').es().cp()
            break;
        case 'coil_2':
            graphics.ss(line_width).s(color).p("EBdwAPoMhdwAAA").es().f(color).p("AW+HWIAAQkMAv0AAAIAAwkMgv0AAA").cp().ef();
            //graphics.ss(line_width).s(color).p("AeAPoI+AAA").es().ss(line_width).s(color).p("EBdwAPoI/GAA").es().f(color).ss(1).s(color).p("EBBAAUKMgk4AAAIAApiMAk4AAAIAAJi").cp().ef().es();
            break;
        case 'coil':
            graphics.ss(line_width).s(color).p("EBdwAPoMhdwAAA").es().f(color).p("AW+HWIAAQkMAv0AAAIAAwkMgv0AAA").cp().ef();
            //graphics.ss(line_width).s(color).p("AeAPoI+AAA").es().ss(line_width).s(color).p("EBdwAPoI/GAA").es().f(color).ss(1).s(color).p("EBBAAUKMgk4AAAIAApiMAk4AAAIAAJi").cp().ef().es();
            break;
        case 'capacitor':
            graphics.ss(line_width).s(color).p('AccPoI8cAA').es().ss(line_width).s(color).p('EA+gAPoI8wAA').es().ss(line_width).s(color).p('AccAUIAAee').es().ss(line_width).s(color).p('AccAUIAAee').es().ss(line_width).s(color).p('EAhwAAUIAAee').es();
            break;
        case 'car_battery':
            graphics.ss(line_width).s(color).p('APoPoIAAfQ').es().ss(line_width).s(color).p('ATiAAMAAAA+g').es().ss(line_width).s(color).p('AXcPoIAAfQ').es().ss(line_width).s(color).p('AbWAAMAAAA+g').es().ss(line_width).s(color).p('AfQPoIAAfQ').es().ss(line_width).s(color).p('EAjKAAAMAAAA+g').es().ss(line_width).s(color).p('EAnEAPoIAAfQ').es().ss(line_width).s(color).p('EAq+AAAMAAAA+g').es().ss(line_width).s(color).p('EAu4APoIAAfQ').es().ss(line_width).s(color).p('EAyyAAAMAAAA+g').es().ss(line_width).s(color).p('AAAfQIPoAA').es().ss(line_width).s(color).p('EAyyAfQILuAA').es();
            break;
        case 'dc_voltage_source':
            graphics.ss(line_width).s(color).p('AfQPoIAAfQ').es().ss(line_width).s(color).p('EAjKAAAMAAAA+g').es().ss(line_width).s(color).p('AAAfQIfQAA').es().ss(line_width).s(color).p('EAjKAfQIbWAA').es();
            break;
        case 'relais_with_diode':
            graphics.f(background).ss(line_width).s(color).p("EBOIAAAI/QPeIfQPyIAA/Q").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAA/Q").es().ss(line_width).s(color).p("EAAAA+gIZoAAMAAAgu4IMMgKMAxOAAAIMMAKMAAAAu4IZyAA").es().ss(line_width).s(color).p("EBZOBtYMAAAhGUMg1mAAAMAAABGUMA1mAAA").cp().es().ss(line_width).s(color).p("EB9ABdwMgu4AAA").es().ss(line_width).s(color).p("EAAABdwMAu4AAAIfQn0").es().f(background).ss(line_width).s(color).p("EBUYAz4IAAVQMgr6AAAIAA1QMAr6AAA").cp().ef().es().ss(line_width).s(color).p("EAZoA+gIO2AA").es().ss(line_width).s(color).p("EBUYA+gIO2AA").es().ss(line_width).s(color).p("EA+WBZYIAKwQIAAAA").es().ss(line_width).s(color).p("EA2sBJIIPo1Q").es().f(color).p("EAZoA8oYhGAAgyAyAABGYAAA8AyAyBGAAYA8AAA8gyAAg8YAAhGg8gyg8AA").cp().ef().f(color).p("EBjYA8oYg8AAg8AyAABGYAAA8A8AyA8AAYBGAAAygyAAg8YAAhGgygyhGAA").cp().ef();
            break;
        case 'relais_with_diode_closed':
            graphics.f(background).ss(line_width).s(color).p("EBOIAAAI/QPeIfQPyIAA/Q").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAA/Q").es().ss(line_width).s(color).p("EAAAA+gIZoAAMAAAgu4IMMgKMAxOAAAIMMAKMAAAAu4IZyAA").es().ss(line_width).s(color).p("EBZOBtYMAAAhGUMg1mAAAMAAABGUMA1mAAA").cp().es().f(background).ss(line_width).s(color).p("EBUYAz4IAAVQMgr6AAAIAA1QMAr6AAA").cp().ef().es().ss(line_width).s(color).p("EAZoA+gIO2AA").es().ss(line_width).s(color).p("EBUYA+gIO2AA").es().ss(line_width).s(color).p("EA+WBiwIAK5oIAAAA").es().ss(line_width).s(color).p("EA2sBJIIPo1Q").es().f(color).p("EAZoA8oYhGAAgyAyAABGYAAA8AyAyBGAAYA8AAA8gyAAg8YAAhGg8gyg8AA").cp().ef().f(color).p("EBjYA8oYg8AAg8AyAABGYAAA8A8AyA8AAYBGAAAygyAAg8YAAhGgygyhGAA").cp().ef().ss(line_width).s(color).p("EB9ABdwMgu4AAAIAANm").es().ss(line_width).s(color).p("EAAABdwMAu4AAAMAjKALu").es().ss(line_width).s(color).p("EAnEBnmILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'dc_voltage_terminal':
            graphics.f(background).ss(line_width).s(color).moveTo(0, 100).lineTo(400, 100);
            break;
        case 'amperemeter':
        case 'voltmeter':
        case 'ohmmeter':
            graphics.f(background).ss(line_width).s(color).p("EBdwAfQMhdwAAA").ef().es().f(background).ss(line_width).s(color).p("EBOIAfQYAARMuEOExMAAYxWAAt6uEAAxMYAAxWN6t6RWAAYRMAAOEN6AARW").cp().ef().es();
            break;
        case 'wattmeter':
            graphics.f(background).ss(line_width).s(color).p('EBdwAu4MhdwAAA').es().ef().f(background).ss(line_width).s(color).p('EAvCBdwMAAAhdw').es().ef().f(background).ss(line_width).s(color).p('EBOIAu4cAAAARRgN/AN/gRRAAAcgRRAAAgN/gN/AAAgRRcAAAgRRAN/gN/ARRAAAcARRAAAAN/AN/AAAARR').es().ef().cp()
            break;
        case 'oscilloscope':
            graphics.f(background).ss(line_width).s(color).p("EBdwAfQMhdwAAA").ef().es().f(background).ss(line_width).s(color).p("EBOIAfQYAARMuEOExMAAYxWAAt6uEAAxMYAAxWN6t6RWAAYRMAAOEN6AARW").cp().ef().es().ss(10).s(color).p("EAXcAnEIXcvoIAAPoIXcvo").es();
            break;
        case 'fuse':
            graphics.f(background).ss(line_width).s(color).p('EA2sAH0Mgu4AAAMAAAAPoMAu4AAAMAAAgPo').es().ef().ss(line_width).s(color).p('EA+gAPoMg+gAAA').es().cp();
            break;
        case 'solar_cell':
            graphics.ss(6).s(0x000000).p('EAAAAfQMAH0AAAMA2sAAA').es().f(0xffffff).ss(6).s(0x000000).p('EA2sAyyMgu4AAAMAAAgnEMAu4AAAMAAAAnE').es().ef().f(0x000000).p('EAH0ALuMATiATiMgTiATiMAAAgnE').ef().cp();
            break;
    }
};

var draw_ANSI = function (graphics, type) {
    var line_width = re['line_width'];
    var color = re['color'];
    var background = re['background'];
    var alpha = re['alpha'];

    switch (type) {
        case 'ground':
            graphics.ss(line_width).s(color).p('APoAAIAAXc').es().ss(line_width).s(color).p('AAAXcIfQAA').es().ss(line_width).s(color).p('EAH0AnEIPoAA').es().ss(line_width).s(color).p('AD6fQIXcAA').es();
            break;
        case 'led':
            graphics.f(0xffffff).p("EAPoA+WIfGvyI/GvoIAAfa").cp().ef().beginFill(background, alpha).ss(line_width).s(color).p("EAPoA+qIfGvyI/GvoIAAfa").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAAfQ").es().ss(line_width).s(color).p("EA+WAu4Mg+WAAA").es().ss(line_width).s(color).p("EAfuAhc").es().f(color).p("AVkTOIo6leIFAJYIBkhkIJYJ2IAygyIpYp2IBkhk").cp().ef().f(color).p("AcwMqIBuhuIpElUIFKJOIBkhkIImIwIAygyIowom").cp().ef();
            break;
        case 'diode':
            graphics.f(background).ss(line_width).s(color).p("APefQIfQvoI/QvoIAAfQ").cp().ef().es().ss(line_width).s(color).p("EAuuAAAIAAfQ").es().ss(line_width).s(color).p("EA+gAPoMg+gAAA").es();
            break;
        case 'z_diode':
            graphics.f(background).ss(line_width).s(color).p("APofQIfGvoI/GveIAAfG").cp().ef().es().ss(line_width).s(color).p("EAuuAAAIAAfQInqAA").es().ss(line_width).s(color).p("EA+gAPoMg+gAAA").es();
            break;
        case 'dc_motor':
            graphics.f(background).ss(line_width).s(color).p("EBtOAuuYAAZ808U85yAAY58AA0808AA58YAA5yU808Z8AAYZyAAU8U8AAZy").cp().ef().es().ss(line_width).s(color).p("APoPo").es().ss(line_width).s(color).p("EBKOAPo").es().f(background).ss(18).s(color).p("EBtYAPo").ef().es().ss(line_width).s(color).p("EAPoAuuIvoAA").es().ss(line_width).s(color).p("EBtOAuuIPyAA").es().f(color).p("EBGKAtKYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'shunt_dc_motor':
            graphics.f(background).ss(line_width).s(color).p("ECMeBN+YAAZ808U85yAAY58AA0808AA58YAA5yU81GZ8AAYZyAAU8VGAAZy").cp().ef().es().ss(line_width).s(color).p("EAu4Au4").es().ss(line_width).s(color).p("EBpeAu4").es().f(background).ss(18).s(color).p("ECMoAu4").ef().es().ss(line_width).s(color).p("EAu4BN+IvoAKMAAAhOI").es().ss(line_width).s(color).p("ECMeBN+IPyAKMAAAhOI").es().ss(line_width).s(color).p("EBOICMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EBdwCMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EBtYCMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EB9ACMeYgKkijIjIksgKYkiAAjIDSgKEi").es().ss(line_width).s(color).p("EBN+BdwIfaAA").es().ss(line_width).s(color).p("EBZ2BlkIr4AA").es().ss(line_width).s(color).p("EBhqBlkILuAA").es().ss(line_width).s(color).p("EAfQBOIMAAAA+gIfQAA").es().ss(line_width).s(color).p("ECcQBOIMAAAA+gI/QAA").es().f(color).p("EBlaBIgYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'series_dc_motor':
            graphics.f(background).ss(line_width).s(color).p("ECMeBN+YAAZ808U85yAAY58AA0808AA58YAA5yU81GZ8AAYZyAAU8VGAAZy").cp().ef().es().ss(line_width).s(color).p("EAu4Au4").es().ss(line_width).s(color).p("EBpeAu4").es().f(background).ss(18).s(color).p("ECMoAu4").ef().es().ss(line_width).s(color).p("EAu4BN+IvoAKMAAAhOI").es().ss(line_width).s(color).p("ECMeBN+IPyAKIAA/Q").es().ss(line_width).s(color).p("ECcGAfQYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("ECcGAPoYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("ECcGAAAYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("EBN+BdwIfaAA").es().ss(line_width).s(color).p("EBZ2BlkIr4AA").es().ss(line_width).s(color).p("EBhqBlkILuAA").es().f(color).p("EBlaBIgYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'lamp':
            graphics.f(0xffffff).p("APePoYAAImHCHCIwAAYImAAHCnCAAomYAAownCm4omAAYowAAnCG4AAIw").cp().ef().beginFill(background, alpha).ss(line_width).s(color).p("APePoYAAImHCHCIwAAYImAAHCnCAAomYAAownCm4omAAYowAAnCG4AAIw").cp().ef().es().ss(line_width).s(color).p("AUKEiIWCWM").es().ss(line_width).s(color).p("EAqMAEiI2CWM").es().ss(line_width).s(color).p("AAAPoIPogK").es().ss(line_width).s(color).p("EAu4APeIPoAA").es();
            break;
        case 'push_button':
            graphics.ss(line_width).s(color).p("EA+gA+gIfQAA").es().ss(line_width).s(color).p("EAAAA+gIfQAAMAjKgRq").es().ss(line_width).s(color).p("EAnEAPAIAAnMIPoAAIAAHM").es().ss(line_width).s(color).p("EAu4AnEIAAPo").es().ss(line_width).s(color).p("EAu4ATiIAAPo").es().ss(line_width).s(color).p("EAu4AH0IAAH0").es();
            break;
        case 'push_button_pushed':
            graphics.ss(line_width).s(color).p("EBdwA+gI/QAAIAAPo").es().ss(line_width).s(color).p("EAAAA+gIfQAAMAjKALu").es().ss(line_width).s(color).p("EAnEAauIAAnMIPoAAIAAHM").es().ss(line_width).s(color).p("EAu4AyyIAAQ4").es().ss(line_width).s(color).p("EAu4AfQIAAPo").es().ss(line_width).s(color).p("EAu4ATiIAAH0").es().ss(line_width).s(color).p("EAfGBRkILuAAIAACCIH+j6IAAAAIn+j6IAACgIruAA").es();
            break;
        case 'push_button_no_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAQ4').es().ss(line_width).s(color).p('EAu4AfQMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAH0').es().ss(line_width).s(color).p('EAbMBMGMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().cp()
            break;
        case 'push_button_no_off':
            graphics.ss(line_width).s(color).p('EA+gA+gMAfQAAA').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMgRm').es().ss(line_width).s(color).p('EAnEAPFMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AnEMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAPo').es().ss(line_width).s(color).p('EAu4AH0MAAAAH0').es().cp()
            break;
        case 'push_button_nc_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAfQATi').es().ss(line_width).s(color).p('EAnEAetMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4A2sMAAAARW').es().ss(line_width).s(color).p('EAu4AjKMAAAAPo').es().ss(line_width).s(color).p('EAu4AXcMAAAAH0').es().ss(line_width).s(color).p('EAbMBQAMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().cp()
            break;
        case 'push_button_nc_off':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAQ4').es().ss(line_width).s(color).p('EAu4AfQMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAH0').es().cp()
            break;
        case 'switch_on':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKALu").es().ss(line_width).s(color).p("EAmSAJiILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'switch_off':
            graphics.ss(line_width).s(color).p("EA+gAPoIfQAA").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKgRf").es();
            break;
        case 'switch_no_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAbMBMGMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().ss(line_width).s(color).p('EAu4AyyMAAAAH0').es().ss(line_width).s(color).p('EAu4AjKMAAAAH0').es().ss(line_width).s(color).p('EAu4ATiMAAAALu').es().ss(line_width).s(color).p('EAu4Aq+MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAD6').es().cp();
            break;
        case 'switch_no_off':
            graphics.ss(line_width).s(color).p('EA+gA+gMAfQAAA').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMgRm').es().ss(line_width).s(color).p('EAnEAPFMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AnEMAAAAH0').es().ss(line_width).s(color).p('EAu4AXcMAAAAH0').es().ss(line_width).s(color).p('EAu4AH0MAAAALu').es().ss(line_width).s(color).p('EAu4AfQMgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4AyyMAAAAD6').es().cp();
            break;
        case 'switch_nc_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAfQATi').es().ss(line_width).s(color).p('EAnEAetMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAbMBQAMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().ss(line_width).s(color).p('EAu4A2sMAAAAH0').es().ss(line_width).s(color).p('EAu4AnEMAAAAH0').es().ss(line_width).s(color).p('EAu4AXcMAAAALu').es().ss(line_width).s(color).p('EAu4Au4MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAKA').es().cp()
            break;
        case 'switch_nc_off':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAH0').es().ss(line_width).s(color).p('EAu4AjKMAAAAH0').es().ss(line_width).s(color).p('EAu4ATiMAAAALu').es().ss(line_width).s(color).p('EAu4Aq+MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAD6').es().cp();
            break;
        case 'opener_on':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAIfQTi").es().ss(line_width).s(color).p("AfGJsILuAAIAACCIH+j6IAAAAIn+j6IAACgIruAA").es();
            break;
        case 'opener_off':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKALu").es();
            break;
        case 'toggle_switch_bottom':
            graphics.ss(line_width).s(color).p("EA+gAPoIAAvoIfQAA").es().ss(line_width).s(color).p("EBdwAfQI/QAAIAAPo").es().ss(line_width).s(color).p("AAAfQIfQAAMAjKALu").es().ss(line_width).s(color).p("EAmSAZKILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'toggle_switch_top':
            graphics.ss(line_width).s(color).p("AAAfQIXcAA").es().ss(line_width).s(color).p("EA+gAfGIfQAA").es().ss(line_width).s(color).p("AAAfGIfQAAMAjKgTY").es().ss(line_width).s(color).p("EA+gAPoIAAvoIfQAA").es();
            break;
        case 'ldr_resistor':
            graphics.ss(line_width).s(color).p("EAMgAu4IsgAA").es().ss(line_width).s(color).p("EA+gAuuIs0AA").es().ss(line_width).s(color).p("EAfGAns").es().f(background).ss(line_width).s(color).p("EA2sAnEMgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es().f(color).p("EA3UAUeIgygyIomIwIhuhuIlUJEIJOlKIhkhkIIwom").cp().ef().f(color).p("EAnsAVaIhkhkIIwomIgygyIomIwIhuhuIlUJEIJOlK").cp().ef();
            break;
        case 'resistor':
            graphics.ss(line_width).s(color).p("AMgPoIsgAA").es().ss(line_width).s(color).p("EA+gAPoItIAA").es().f(background).ss(line_width).s(color).p("EA2sAH0Mgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es();
            break;
        case 'potentiometer':
            graphics.ss(line_width).s(color).p("ANcfQItcAA").es().ss(line_width).s(color).p("EA+gAfQItwAA").es().f(background).ss(line_width).s(color).p("EA2sAXcMgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es().f(color).p("AeyAAIAANIIiWAAIC0KUICqqUIiMAAIAAtIIg8AA").cp().ef().ss(line_width).s(color).p("AeyAAIfuAA").es();
            break;
        case 'ntc':
            graphics.ss(line_width).s(color).p("AH0PoIn0AA").es().ss(line_width).s(color).p("EA+gAPoIn0AA").es().f(background).ss(line_width).s(color).p("AH0XcMAu4AAAIAAvoMgu4AAAIAAPo").cp().ef().es().f(color).p("EA6mALuIAAvn").ef().f(color).p("EA8eADIIh4nBIiCHBIBkAAIAAImIA8AAIAAomIBaAA").cp().ef().f(color).p("EBBoAEiIhaAAIAAobIg8AAIAAIbIhkAAIB4HMICCnM").cp().ef().ss(line_width).s(color).p("AFodOIKKAAIeo7g").es().ss(8).s(color).p("AbgfGYAAAACghQg8C+YgUAyBQCMCMhkYA8goB4n+jcAAYiWAAhGEYGugU").es();
            break;
        case 'ptc':
            graphics.ss(line_width).s(color).p('EAH0APoMgH0AAA').es().ss(line_width).s(color).p('EA+gAPoMgH0AAA').es().f(background).ss(line_width).s(color).p('EAH0AXcMAu4AAAMAAAgPoMgu4AAAMAAAAPo').es().ef().ss(line_width).s(color).p('EAFtAdUMAKOgABMAekgbe').es().f(0x000000).p('EA6mALuMAAAgPo').ef().f(0x000000).p('EA8gADNMgB6gHHMgB6AHHMABcAAAMAAAAIhMAA8AAAMAAAgIhMABcAAA').ef().f(0x000000).p('EA92ADNMABcgAAMAAAAIhMAA8gAAMgAAgIhMABcgAAMgB6gHHMgB6AHH').ef().ss(8).s(color).p('EAbjAfOcAAAAAAACggBWgA5ADBcgAPAAzABHACMACLgBncAA8gAnAB8gH5gDegACcgCXgABgA/AEVAGogAX').es().cp()
            break;
        case 'mosfet_p_left':
            graphics.ss(line_width).s(color).p("AfQPoIfQAA").es().ss(line_width).s(color).p("AfQfQIbWAA").es().ss(line_width).s(color).p("EAfQAu4IfQAA").es().ss(line_width).s(color).p("AcIPoIAAfQIAAAAI8IAA").es().f(color).p("Afae8IAAAUIAAAKIAAAU").ef().ss(line_width).s(color).p("AfQH0IAAPo").es().ss(line_width).s(color).p("EAfQAnEIAAPo").es().ss(line_width).s(color).p("AfQbWIAAH0").es().ss(line_width).s(color).p("EAnEAjKIH0kEIn0jw").es();
            break;
        case 'mosfet_p_right':
            graphics.ss(line_width).s(color).p("AfQPoIfQAA").es().ss(line_width).s(color).p("AfQfQIbWAA").es().ss(line_width).s(color).p("EAfQAu4IfQAA").es().ss(line_width).s(color).p("AcIPoIAAfQIAAAAI8IAA").es().f(color).p("Afae8IAAAUIAAAKIAAAU").ef().ss(line_width).s(color).p("AfQH0IAAPo").es().ss(line_width).s(color).p("EAfQAnEIAAPo").es().ss(line_width).s(color).p("AfQbWIAAH0").es().ss(line_width).s(color).p("EAnEAjKIH0kEIn0jw").es();
            break;
        case 'mosfet_n_left':
            graphics.ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('AfQfQIbWAA').es().ss(line_width).s(color).p('EAfQAu4IfQAA').es().ss(line_width).s(color).p('AcIPoIAAfQIAAAAI8IAA').es().f(color).p('Afae8IAAAUIAAAKIAAAU').ef().ss(line_width).s(color).p('AfQH0IAAPo').es().ss(line_width).s(color).p('EAfQAnEIAAPo').es().ss(line_width).s(color).p('AfQbWIAAH0').es().ss(line_width).s(color).p('EAu4AbWIn0D6IH0D6').es();
            break;
        case 'mosfet_n_right':
            graphics.ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('AfQfQIbWAA').es().ss(line_width).s(color).p('EAfQAu4IfQAA').es().ss(line_width).s(color).p('AcIPoIAAfQIAAAAI8IAA').es().f(color).p('Afae8IAAAUIAAAKIAAAU').ef().ss(line_width).s(color).p('AfQH0IAAPo').es().ss(line_width).s(color).p('EAfQAnEIAAPo').es().ss(line_width).s(color).p('AfQbWIAAH0').es().ss(line_width).s(color).p('EAu4AbWIn0D6IH0D6').es();
            break;
        case 'fet_p_left':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAPoAyyIH0j6In0j6').es();
            break;
        case 'fet_p_right':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAPoAyyIH0j6In0j6').es();
            break;
        case 'fet_n_left':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAXcAq0In0D6IH0D6').es();
            break;
        case 'fet_n_right':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAXcAq0In0D6IH0D6').es();
            break;
        case 'npn_right':
            graphics.ss(line_width).s(color).p('EAfQAu4MgAAgfQ').es().ss(line_width).s(color).p('EAfKAfaMAfWgAA').es().ss(line_width).s(color).p('EAAAA+gMAfCgXc').es().ss(line_width).s(color).p('EAAAAAAMAfCAXc').es().ss(line_width).s(color).p('EAYbAwaMgIdACOMADTgIG').es().cp()
            break;
        case 'npn_left':
            graphics.ss(line_width).s(color).p('EAfQAu4MAAAgfQ').es().ss(line_width).s(color).p('EAfWAfaMgfWgAA').es().ss(line_width).s(color).p('EA+gA+gMgfCgXc').es().ss(line_width).s(color).p('EA+gAAAMgfCAXc').es().ss(line_width).s(color).p('EAmFAwaMAIdACOMgDTgIG').es().cp()
            break;
        case 'pnp_right':
            graphics.ss(line_width).s(color).p('EAfQAPoMgAAAfQ').es().ss(line_width).s(color).p('EAfKAfGMAfqAAA').es().ss(line_width).s(color).p('EgAUAAAMAfVAXc').es().ss(line_width).s(color).p('EgAUA+gMAfVgXc').es().ss(line_width).s(color).p('EAOGAvOMAIpgCOMgDXAIG').es().cp()
            break;
        case 'pnp_left':
            graphics.ss(line_width).s(color).p('EAfQAPoMAAAAfQ').es().ss(line_width).s(color).p('EAfWAfGMgfWAAA').es().ss(line_width).s(color).p('EA+gAAAMgfCAXc').es().ss(line_width).s(color).p('EA+gA+gMgfCgXc').es().ss(6.04013442993164).s(color).p('EAwPAvOMgIkgCOMADVAIG').es().cp()
            break;
        case 'coil_2':
            graphics.ss(line_width).s(color).p('EBdwAPoIvoAAYgKksjIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSAAEsIAAAAYgKksjIjSkiAAYksAAjIDSgKEiIveAA').es();
            break;
        case 'coil':
            graphics.ss(line_width).s(color).p('EBdwAPoIvoAAYgKksjIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSAAEsIAAAAYgKksjIjSkiAAYksAAjIDSgKEiIveAA').es();
            break;
        case 'capacitor':
            graphics.ss(line_width).s(color).p('AccPoI8cAA').es().ss(line_width).s(color).p('EA+gAPoI8wAA').es().ss(line_width).s(color).p('AccAUIAAee').es().ss(line_width).s(color).p('AccAUIAAee').es().ss(line_width).s(color).p('EAhwAAUIAAee').es();
            break;
        case 'car_battery':
            graphics.ss(line_width).s(color).p('APoPoIAAfQ').es().ss(line_width).s(color).p('ATiAAMAAAA+g').es().ss(line_width).s(color).p('AXcPoIAAfQ').es().ss(line_width).s(color).p('AbWAAMAAAA+g').es().ss(line_width).s(color).p('AfQPoIAAfQ').es().ss(line_width).s(color).p('EAjKAAAMAAAA+g').es().ss(line_width).s(color).p('EAnEAPoIAAfQ').es().ss(line_width).s(color).p('EAq+AAAMAAAA+g').es().ss(line_width).s(color).p('EAu4APoIAAfQ').es().ss(line_width).s(color).p('EAyyAAAMAAAA+g').es().ss(line_width).s(color).p('AAAfQIPoAA').es().ss(line_width).s(color).p('EAyyAfQILuAA').es();
            break;
        case 'dc_voltage_source':
            graphics.ss(line_width).s(color).p('AfQPoIAAfQ').es().ss(line_width).s(color).p('EAjKAAAMAAAA+g').es().ss(line_width).s(color).p('AAAfQIfQAA').es().ss(line_width).s(color).p('EAjKAfQIbWAA').es();
            break;
        case 'relais_with_diode':
            graphics.f(background).ss(line_width).s(color).p("EBOIAAAI/QPeIfQPyIAA/Q").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAA/Q").es().ss(line_width).s(color).p("EAAAA+gIZoAAMAAAgu4IMMgKMAxOAAAIMMAKMAAAAu4IZyAA").es().ss(line_width).s(color).p("EBZOBtYMAAAhGUMg1mAAAMAAABGUMA1mAAA").cp().es().ss(line_width).s(color).p("EB9ABdwMgu4AAA").es().ss(line_width).s(color).p("EAAABdwMAu4AAAIfQn0").es().f(background).ss(line_width).s(color).p("EBUYAz4IAAVQMgr6AAAIAA1QMAr6AAA").cp().ef().es().ss(line_width).s(color).p("EAZoA+gIO2AA").es().ss(line_width).s(color).p("EBUYA+gIO2AA").es().ss(line_width).s(color).p("EA+WBZYIAKwQIAAAA").es().ss(line_width).s(color).p("EA2sBJIIPo1Q").es().f(color).p("EAZoA8oYhGAAgyAyAABGYAAA8AyAyBGAAYA8AAA8gyAAg8YAAhGg8gyg8AA").cp().ef().f(color).p("EBjYA8oYg8AAg8AyAABGYAAA8A8AyA8AAYBGAAAygyAAg8YAAhGgygyhGAA").cp().ef();
            break;
        case 'relais_with_diode_closed':
            graphics.f(background).ss(line_width).s(color).p("EBOIAAAI/QPeIfQPyIAA/Q").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAA/Q").es().ss(line_width).s(color).p("EAAAA+gIZoAAMAAAgu4IMMgKMAxOAAAIMMAKMAAAAu4IZyAA").es().ss(line_width).s(color).p("EBZOBtYMAAAhGUMg1mAAAMAAABGUMA1mAAA").cp().es().f(background).ss(line_width).s(color).p("EBUYAz4IAAVQMgr6AAAIAA1QMAr6AAA").cp().ef().es().ss(line_width).s(color).p("EAZoA+gIO2AA").es().ss(line_width).s(color).p("EBUYA+gIO2AA").es().ss(line_width).s(color).p("EA+WBiwIAK5oIAAAA").es().ss(line_width).s(color).p("EA2sBJIIPo1Q").es().f(color).p("EAZoA8oYhGAAgyAyAABGYAAA8AyAyBGAAYA8AAA8gyAAg8YAAhGg8gyg8AA").cp().ef().f(color).p("EBjYA8oYg8AAg8AyAABGYAAA8A8AyA8AAYBGAAAygyAAg8YAAhGgygyhGAA").cp().ef().ss(line_width).s(color).p("EB9ABdwMgu4AAAIAANm").es().ss(line_width).s(color).p("EAAABdwMAu4AAAMAjKALu").es().ss(line_width).s(color).p("EAnEBnmILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'dc_voltage_terminal':
            graphics.f(background).ss(line_width).s(color).moveTo(0, 100).lineTo(400, 100);
            break;
        case 'amperemeter':
        case 'voltmeter':
        case 'ohmmeter':
            graphics.f(background).ss(line_width).s(color).p("EBdwAfQMhdwAAA").ef().es().f(background).ss(line_width).s(color).p("EBOIAfQYAARMuEOExMAAYxWAAt6uEAAxMYAAxWN6t6RWAAYRMAAOEN6AARW").cp().ef().es();
            break;
        case 'wattmeter':
            graphics.f(background).ss(line_width).s(color).p('EBdwAu4MhdwAAA').es().ef().f(background).ss(line_width).s(color).p('EAvCBdwMAAAhdw').es().ef().f(background).ss(line_width).s(color).p('EBOIAu4cAAAARRgN/AN/gRRAAAcgRRAAAgN/gN/AAAgRRcAAAgRRAN/gN/ARRAAAcARRAAAAN/AN/AAAARR').es().ef().cp()
            break;
        case 'oscilloscope':
            graphics.f(background).ss(line_width).s(color).p("EBdwAfQMhdwAAA").ef().es().f(background).ss(line_width).s(color).p("EBOIAfQYAARMuEOExMAAYxWAAt6uEAAxMYAAxWN6t6RWAAYRMAAOEN6AARW").cp().ef().es().ss(10).s(color).p("EAXcAnEIXcvoIAAPoIXcvo").es();
            break;
        case 'fuse':
            graphics.f(background).ss(line_width).s(color).p('EA2sAH0Mgu4AAAMAAAAPoMAu4AAAMAAAgPo').es().ef().ss(line_width).s(color).p('EA+gAPoMg+gAAA').es().cp();
            break;
        case 'solar_cell':
            graphics.ss(6).s(0x000000).p('EAAAAfQMAH0AAAMA2sAAA').es().f(0xffffff).ss(6).s(0x000000).p('EA2sAyyMgu4AAAMAAAgnEMAu4AAAMAAAAnE').es().ef().f(0x000000).p('EAH0ALuMATiATiMgTiATiMAAAgnE').ef().cp();
            break;
    }
};

var draw_DIN = function (graphics, type) {
    var line_width = re['line_width'];
    var color = re['color'];
    var background = re['background'];
    var alpha = re['alpha'];

    switch (type) {
        case 'ground':
            graphics.ss(line_width).s(color).p('APoAAIAAXc').es().ss(line_width).s(color).p('AAAXcIfQAA').es().ss(line_width).s(color).p('EAH0AnEIPoAA').es().ss(line_width).s(color).p('AD6fQIXcAA').es();
            break;
        case 'led':
            graphics.f(0xffffff).p("EAPoA+WIfGvyI/GvoIAAfa").cp().ef().beginFill(background, alpha).ss(line_width).s(color).p("EAPoA+qIfGvyI/GvoIAAfa").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAAfQ").es().ss(line_width).s(color).p("EA+WAu4Mg+WAAA").es().ss(line_width).s(color).p("EAfuAhc").es().f(color).p("AVkTOIo6leIFAJYIBkhkIJYJ2IAygyIpYp2IBkhk").cp().ef().f(color).p("AcwMqIBuhuIpElUIFKJOIBkhkIImIwIAygyIowom").cp().ef();
            break;
        case 'diode':
            graphics.f(background).ss(line_width).s(color).p("APefQIfQvoI/QvoIAAfQ").cp().ef().es().ss(line_width).s(color).p("EAuuAAAIAAfQ").es().ss(line_width).s(color).p("EA+gAPoMg+gAAA").es();
            break;
        case 'z_diode':
            graphics.f(background).ss(line_width).s(color).p("APofQIfGvoI/GveIAAfG").cp().ef().es().ss(line_width).s(color).p("EAuuAAAIAAfQInqAA").es().ss(line_width).s(color).p("EA+gAPoMg+gAAA").es();
            break;
        case 'dc_motor':
            graphics.f(background).ss(line_width).s(color).p("EBtOAuuYAAZ808U85yAAY58AA0808AA58YAA5yU808Z8AAYZyAAU8U8AAZy").cp().ef().es().ss(line_width).s(color).p("APoPo").es().ss(line_width).s(color).p("EBKOAPo").es().f(background).ss(18).s(color).p("EBtYAPo").ef().es().ss(line_width).s(color).p("EAPoAuuIvoAA").es().ss(line_width).s(color).p("EBtOAuuIPyAA").es().f(color).p("EBGKAtKYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'shunt_dc_motor':
            graphics.f(background).ss(line_width).s(color).p("ECMeBN+YAAZ808U85yAAY58AA0808AA58YAA5yU81GZ8AAYZyAAU8VGAAZy").cp().ef().es().ss(line_width).s(color).p("EAu4Au4").es().ss(line_width).s(color).p("EBpeAu4").es().f(background).ss(18).s(color).p("ECMoAu4").ef().es().ss(line_width).s(color).p("EAu4BN+IvoAKMAAAhOI").es().ss(line_width).s(color).p("ECMeBN+IPyAKMAAAhOI").es().ss(line_width).s(color).p("EBOICMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EBdwCMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EBtYCMeYgKkijIjIkigKYksAAjIDSAAEi").es().ss(line_width).s(color).p("EB9ACMeYgKkijIjIksgKYkiAAjIDSgKEi").es().ss(line_width).s(color).p("EBN+BdwIfaAA").es().ss(line_width).s(color).p("EBZ2BlkIr4AA").es().ss(line_width).s(color).p("EBhqBlkILuAA").es().ss(line_width).s(color).p("EAfQBOIMAAAA+gIfQAA").es().ss(line_width).s(color).p("ECcQBOIMAAAA+gI/QAA").es().f(color).p("EBlaBIgYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'series_dc_motor':
            graphics.f(background).ss(line_width).s(color).p("ECMeBN+YAAZ808U85yAAY58AA0808AA58YAA5yU81GZ8AAYZyAAU8VGAAZy").cp().ef().es().ss(line_width).s(color).p("EAu4Au4").es().ss(line_width).s(color).p("EBpeAu4").es().f(background).ss(18).s(color).p("ECMoAu4").ef().es().ss(line_width).s(color).p("EAu4BN+IvoAKMAAAhOI").es().ss(line_width).s(color).p("ECMeBN+IPyAKIAA/Q").es().ss(line_width).s(color).p("ECcGAfQYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("ECcGAPoYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("ECcGAAAYkiAAjIDIAAEsYgKEiDSDIEiAK").es().ss(line_width).s(color).p("EBN+BdwIfaAA").es().ss(line_width).s(color).p("EBZ2BlkIr4AA").es().ss(line_width).s(color).p("EBhqBlkILuAA").es().f(color).p("EBlaBIgYgKjIgKjmAAiqIAAAAYgyCgg8CqhGC+IjwKoIiMAAIjmqUYg8jIg8i0goigIAAAAYAACqgKDmgUDSIgeJYIiqAAIBa1uIDmAAIDwKeYA8CqAoCWAoCWIAAAAYAoiMAyigA8iqID6qeIDmAAIBQVuIiqAAIgopi").cp().ef();
            break;
        case 'lamp':
            graphics.f(0xffffff).p("APePoYAAImHCHCIwAAYImAAHCnCAAomYAAownCm4omAAYowAAnCG4AAIw").cp().ef().beginFill(background, alpha).ss(line_width).s(color).p("APePoYAAImHCHCIwAAYImAAHCnCAAomYAAownCm4omAAYowAAnCG4AAIw").cp().ef().es().ss(line_width).s(color).p("AUKEiIWCWM").es().ss(line_width).s(color).p("EAqMAEiI2CWM").es().ss(line_width).s(color).p("AAAPoIPogK").es().ss(line_width).s(color).p("EAu4APeIPoAA").es();
            break;
        case 'push_button':
            graphics.ss(line_width).s(color).p("EA+gA+gIfQAA").es().ss(line_width).s(color).p("EAAAA+gIfQAAMAjKgRq").es().ss(line_width).s(color).p("EAnEAPAIAAnMIPoAAIAAHM").es().ss(line_width).s(color).p("EAu4AnEIAAPo").es().ss(line_width).s(color).p("EAu4ATiIAAPo").es().ss(line_width).s(color).p("EAu4AH0IAAH0").es();
            break;
        case 'push_button_pushed':
            graphics.ss(line_width).s(color).p("EBdwA+gI/QAAIAAPo").es().ss(line_width).s(color).p("EAAAA+gIfQAAMAjKALu").es().ss(line_width).s(color).p("EAnEAauIAAnMIPoAAIAAHM").es().ss(line_width).s(color).p("EAu4AyyIAAQ4").es().ss(line_width).s(color).p("EAu4AfQIAAPo").es().ss(line_width).s(color).p("EAu4ATiIAAH0").es().ss(line_width).s(color).p("EAfGBRkILuAAIAACCIH+j6IAAAAIn+j6IAACgIruAA").es();
            break;
        case 'push_button_no_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAQ4').es().ss(line_width).s(color).p('EAu4AfQMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAH0').es().ss(line_width).s(color).p('EAbMBMGMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().cp()
            break;
        case 'push_button_no_off':
            graphics.ss(line_width).s(color).p('EA+gA+gMAfQAAA').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMgRm').es().ss(line_width).s(color).p('EAnEAPFMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AnEMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAPo').es().ss(line_width).s(color).p('EAu4AH0MAAAAH0').es().cp()
            break;
        case 'push_button_nc_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAfQATi').es().ss(line_width).s(color).p('EAnEAetMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4A2sMAAAARW').es().ss(line_width).s(color).p('EAu4AjKMAAAAPo').es().ss(line_width).s(color).p('EAu4AXcMAAAAH0').es().ss(line_width).s(color).p('EAbMBQAMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().cp()
            break;
        case 'push_button_nc_off':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAQ4').es().ss(line_width).s(color).p('EAu4AfQMAAAAPo').es().ss(line_width).s(color).p('EAu4ATiMAAAAH0').es().cp()
            break;
        case 'switch_on':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKALu").es().ss(line_width).s(color).p("EAmSAJiILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'switch_off':
            graphics.ss(line_width).s(color).p("EA+gAPoIfQAA").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKgRf").es();
            break;
        case 'switch_no_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAbMBMGMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().ss(line_width).s(color).p('EAu4AyyMAAAAH0').es().ss(line_width).s(color).p('EAu4AjKMAAAAH0').es().ss(line_width).s(color).p('EAu4ATiMAAAALu').es().ss(line_width).s(color).p('EAu4Aq+MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAD6').es().cp();
            break;
        case 'switch_no_off':
            graphics.ss(line_width).s(color).p('EA+gA+gMAfQAAA').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMgRm').es().ss(line_width).s(color).p('EAnEAPFMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AnEMAAAAH0').es().ss(line_width).s(color).p('EAu4AXcMAAAAH0').es().ss(line_width).s(color).p('EAu4AH0MAAAALu').es().ss(line_width).s(color).p('EAu4AfQMgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4AyyMAAAAD6').es().cp();
            break;
        case 'switch_nc_on':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAfQATi').es().ss(line_width).s(color).p('EAnEAetMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAbMBQAMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().ss(line_width).s(color).p('EAu4A2sMAAAAH0').es().ss(line_width).s(color).p('EAu4AnEMAAAAH0').es().ss(line_width).s(color).p('EAu4AXcMAAAALu').es().ss(line_width).s(color).p('EAu4Au4MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAKA').es().cp()
            break;
        case 'switch_nc_off':
            graphics.ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAH0').es().ss(line_width).s(color).p('EAu4AjKMAAAAH0').es().ss(line_width).s(color).p('EAu4ATiMAAAALu').es().ss(line_width).s(color).p('EAu4Aq+MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAD6').es().cp();
            break;
        case 'opener_on':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAIfQTi").es().ss(line_width).s(color).p("AfGJsILuAAIAACCIH+j6IAAAAIn+j6IAACgIruAA").es();
            break;
        case 'opener_off':
            graphics.ss(line_width).s(color).p("EBdwAPoI/QAAIAAPo").es().ss(line_width).s(color).p("AAAPoIfQAAMAjKALu").es();
            break;
        case 'toggle_switch_bottom':
            graphics.ss(line_width).s(color).p('EA+gAu4MAAAgPoMAfQAAA').es().ss(line_width).s(color).p('EBdwA+gMgfQAAAMAAAAPo').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjMALu').es().ss(line_width).s(color).p('EAbMBMGMALuAAAMAAAACCMAH+gD6MAAAAAAMgH+gD6MAAAACgMgLuAAA').es().ss(line_width).s(color).p('EAnEAazMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AyyMAAAAH0').es().ss(line_width).s(color).p('EAu4AjKMAAAAH0').es().ss(line_width).s(color).p('EAu4ATiMAAAALu').es().ss(line_width).s(color).p('EAu4Aq+MgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4A+gMAAAAD6').es().cp()
            break;
        case 'toggle_switch_top':
            graphics.ss(line_width).s(color).p('EAAAA+gMAXcAAA').es().ss(line_width).s(color).p('EA+gA+gMAfQAAA').es().ss(line_width).s(color).p('EAAAA+gMAfQAAAMAjKgTi').es().ss(line_width).s(color).p('EA+gAu4MAAAgPoMAfQAAA').es().ss(line_width).s(color).p('EAnEAPFMAAAgHRMAPoAAAMAAAAHR').es().ss(line_width).s(color).p('EAu4AnEMAAAAH0').es().ss(line_width).s(color).p('EAu4AXcMAAAAH0').es().ss(line_width).s(color).p('EAu4AH0MAAAALu').es().ss(line_width).s(color).p('EAu4AfQMgLuAD6MALuAD6').es().ss(line_width).s(color).p('EAu4AyyMAAAAD6').es().cp()
            break;
        case 'ldr_resistor':
            graphics.ss(line_width).s(color).p("EAMgAu4IsgAA").es().ss(line_width).s(color).p("EA+gAuuIs0AA").es().ss(line_width).s(color).p("EAfGAns").es().f(background).ss(line_width).s(color).p("EA2sAnEMgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es().f(color).p("EA3UAUeIgygyIomIwIhuhuIlUJEIJOlKIhkhkIIwom").cp().ef().f(color).p("EAnsAVaIhkhkIIwomIgygyIomIwIhuhuIlUJEIJOlK").cp().ef();
            break;
        case 'resistor':
            graphics.ss(line_width).s(color).p("AMgPoIsgAA").es().ss(line_width).s(color).p("EA+gAPoItIAA").es().f(background).ss(line_width).s(color).p("EA2sAH0Mgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es();
            break;
        case 'potentiometer':
            graphics.ss(line_width).s(color).p("ANcfQItcAA").es().ss(line_width).s(color).p("EA+gAfQItwAA").es().f(background).ss(line_width).s(color).p("EA2sAXcMgu4AAAIAAPoMAu4AAAIAAvo").cp().ef().es().f(color).p("AeyAAIAANIIiWAAIC0KUICqqUIiMAAIAAtIIg8AA").cp().ef().ss(line_width).s(color).p("AeyAAIfuAA").es();
            break;
        case 'ntc':
            graphics.ss(line_width).s(color).p("AH0PoIn0AA").es().ss(line_width).s(color).p("EA+gAPoIn0AA").es().f(background).ss(line_width).s(color).p("AH0XcMAu4AAAIAAvoMgu4AAAIAAPo").cp().ef().es().f(color).p("EA6mALuIAAvn").ef().f(color).p("EA8eADIIh4nBIiCHBIBkAAIAAImIA8AAIAAomIBaAA").cp().ef().f(color).p("EBBoAEiIhaAAIAAobIg8AAIAAIbIhkAAIB4HMICCnM").cp().ef().ss(line_width).s(color).p("AFodOIKKAAIeo7g").es().ss(8).s(color).p("AbgfGYAAAACghQg8C+YgUAyBQCMCMhkYA8goB4n+jcAAYiWAAhGEYGugU").es();
            break;
        case 'ptc':
            graphics.ss(line_width).s(color).p('EAH0APoMgH0AAA').es().ss(line_width).s(color).p('EA+gAPoMgH0AAA').es().f(background).ss(line_width).s(color).p('EAH0AXcMAu4AAAMAAAgPoMgu4AAAMAAAAPo').es().ef().ss(line_width).s(color).p('EAFtAdUMAKOgABMAekgbe').es().f(0x000000).p('EA6mALuMAAAgPo').ef().f(0x000000).p('EA8gADNMgB6gHHMgB6AHHMABcAAAMAAAAIhMAA8AAAMAAAgIhMABcAAA').ef().f(0x000000).p('EA92ADNMABcgAAMAAAAIhMAA8gAAMgAAgIhMABcgAAMgB6gHHMgB6AHH').ef().ss(8).s(color).p('EAbjAfOcAAAAAAACggBWgA5ADBcgAPAAzABHACMACLgBncAA8gAnAB8gH5gDegACcgCXgABgA/AEVAGogAX').es().cp()
            break;
        case 'mosfet_p_left':
            graphics.ss(line_width).s(color).p("AfQPoIfQAA").es().ss(line_width).s(color).p("AfQfQIbWAA").es().ss(line_width).s(color).p("EAfQAu4IfQAA").es().ss(line_width).s(color).p("AcIPoIAAfQIAAAAI8IAA").es().f(color).p("Afae8IAAAUIAAAKIAAAU").ef().ss(line_width).s(color).p("AfQH0IAAPo").es().ss(line_width).s(color).p("EAfQAnEIAAPo").es().ss(line_width).s(color).p("AfQbWIAAH0").es().ss(line_width).s(color).p("EAnEAjKIH0kEIn0jw").es();
            break;
        case 'mosfet_p_right':
            graphics.ss(line_width).s(color).p("AfQPoIfQAA").es().ss(line_width).s(color).p("AfQfQIbWAA").es().ss(line_width).s(color).p("EAfQAu4IfQAA").es().ss(line_width).s(color).p("AcIPoIAAfQIAAAAI8IAA").es().f(color).p("Afae8IAAAUIAAAKIAAAU").ef().ss(line_width).s(color).p("AfQH0IAAPo").es().ss(line_width).s(color).p("EAfQAnEIAAPo").es().ss(line_width).s(color).p("AfQbWIAAH0").es().ss(line_width).s(color).p("EAnEAjKIH0kEIn0jw").es();
            break;
        case 'mosfet_n_left':
            graphics.ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('AfQfQIbWAA').es().ss(line_width).s(color).p('EAfQAu4IfQAA').es().ss(line_width).s(color).p('AcIPoIAAfQIAAAAI8IAA').es().f(color).p('Afae8IAAAUIAAAKIAAAU').ef().ss(line_width).s(color).p('AfQH0IAAPo').es().ss(line_width).s(color).p('EAfQAnEIAAPo').es().ss(line_width).s(color).p('AfQbWIAAH0').es().ss(line_width).s(color).p('EAu4AbWIn0D6IH0D6').es();
            break;
        case 'mosfet_n_right':
            graphics.ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('AfQfQIbWAA').es().ss(line_width).s(color).p('EAfQAu4IfQAA').es().ss(line_width).s(color).p('AcIPoIAAfQIAAAAI8IAA').es().f(color).p('Afae8IAAAUIAAAKIAAAU').ef().ss(line_width).s(color).p('AfQH0IAAPo').es().ss(line_width).s(color).p('EAfQAnEIAAPo').es().ss(line_width).s(color).p('AfQbWIAAH0').es().ss(line_width).s(color).p('EAu4AbWIn0D6IH0D6').es();
            break;
        case 'fet_p_left':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAPoAyyIH0j6In0j6').es();
            break;
        case 'fet_p_right':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAPoAyyIH0j6In0j6').es();
            break;
        case 'fet_n_left':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAXcAq0In0D6IH0D6').es();
            break;
        case 'fet_n_right':
            graphics.ss(line_width).s(color).p('AfGH0MAAAAu4').es().ss(line_width).s(color).p('EAfGAu4').es().ss(line_width).s(color).p('EAAAAuuIfGAAIfaAK').es().ss(line_width).s(color).p('AfQPoIfQAA').es().ss(line_width).s(color).p('EAXcAq0In0D6IH0D6').es();
            break;
        case 'npn_right':
            graphics.ss(line_width).s(color).p('EAfQAu4MgAAgfQ').es().ss(line_width).s(color).p('EAfKAfaMAfWgAA').es().ss(line_width).s(color).p('EAAAA+gMAfCgXc').es().ss(line_width).s(color).p('EAAAAAAMAfCAXc').es().ss(line_width).s(color).p('EAYbAwaMgIdACOMADTgIG').es().cp()
            break;
        case 'npn_left':
            graphics.ss(line_width).s(color).p('EAfQAu4MAAAgfQ').es().ss(line_width).s(color).p('EAfWAfaMgfWgAA').es().ss(line_width).s(color).p('EA+gA+gMgfCgXc').es().ss(line_width).s(color).p('EA+gAAAMgfCAXc').es().ss(line_width).s(color).p('EAmFAwaMAIdACOMgDTgIG').es().cp()
            break;
        case 'pnp_right':
            graphics.ss(line_width).s(color).p('EAfQAPoMgAAAfQ').es().ss(line_width).s(color).p('EAfKAfGMAfqAAA').es().ss(line_width).s(color).p('EgAUAAAMAfVAXc').es().ss(line_width).s(color).p('EgAUA+gMAfVgXc').es().ss(line_width).s(color).p('EAOGAvOMAIpgCOMgDXAIG').es().cp()
            break;
        case 'pnp_left':
            graphics.ss(line_width).s(color).p('EAfQAPoMAAAAfQ').es().ss(line_width).s(color).p('EAfWAfGMgfWAAA').es().ss(line_width).s(color).p('EA+gAAAMgfCAXc').es().ss(line_width).s(color).p('EA+gA+gMgfCgXc').es().ss(6.04013442993164).s(color).p('EAwPAvOMgIkgCOMADVAIG').es().cp()
            break;
        case 'coil_2':
            graphics.ss(line_width).s(color).p('EBdwAPoIvoAAYgKksjIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSAAEsIAAAAYgKksjIjSkiAAYksAAjIDSgKEiIveAA').es();
            break;
        case 'coil':
            graphics.ss(line_width).s(color).p('EBdwAPoIvoAAYgKksjIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSgKEiIAAAAYAAkijIjSksAAYkiAAjIDSAAEsIAAAAYgKksjIjSkiAAYksAAjIDSgKEiIveAA').es();
            break;
        case 'capacitor':
            graphics.ss(line_width).s(color).p('AccPoI8cAA').es().ss(line_width).s(color).p('EA+gAPoI8wAA').es().ss(line_width).s(color).p('AccAUIAAee').es().ss(line_width).s(color).p('AccAUIAAee').es().ss(line_width).s(color).p('EAhwAAUIAAee').es();
            break;
        case 'car_battery':
            graphics.ss(line_width).s(color).p('APoPoIAAfQ').es().ss(line_width).s(color).p('ATiAAMAAAA+g').es().ss(line_width).s(color).p('AXcPoIAAfQ').es().ss(line_width).s(color).p('AbWAAMAAAA+g').es().ss(line_width).s(color).p('AfQPoIAAfQ').es().ss(line_width).s(color).p('EAjKAAAMAAAA+g').es().ss(line_width).s(color).p('EAnEAPoIAAfQ').es().ss(line_width).s(color).p('EAq+AAAMAAAA+g').es().ss(line_width).s(color).p('EAu4APoIAAfQ').es().ss(line_width).s(color).p('EAyyAAAMAAAA+g').es().ss(line_width).s(color).p('AAAfQIPoAA').es().ss(line_width).s(color).p('EAyyAfQILuAA').es();
            break;
        case 'dc_voltage_source':
            graphics.ss(line_width).s(color).p('AfQPoIAAfQ').es().ss(line_width).s(color).p('EAjKAAAMAAAA+g').es().ss(line_width).s(color).p('AAAfQIfQAA').es().ss(line_width).s(color).p('EAjKAfQIbWAA').es();
            break;
        case 'relais_with_diode':
            graphics.f(background).ss(line_width).s(color).p("EBOIAAAI/QPeIfQPyIAA/Q").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAA/Q").es().ss(line_width).s(color).p("EAAAA+gIZoAAMAAAgu4IMMgKMAxOAAAIMMAKMAAAAu4IZyAA").es().ss(line_width).s(color).p("EBZOBtYMAAAhGUMg1mAAAMAAABGUMA1mAAA").cp().es().ss(line_width).s(color).p("EB9ABdwMgu4AAA").es().ss(line_width).s(color).p("EAAABdwMAu4AAAIfQn0").es().f(background).ss(line_width).s(color).p("EBUYAz4IAAVQMgr6AAAIAA1QMAr6AAA").cp().ef().es().ss(line_width).s(color).p("EAZoA+gIO2AA").es().ss(line_width).s(color).p("EBUYA+gIO2AA").es().ss(line_width).s(color).p("EA+WBZYIAKwQIAAAA").es().ss(line_width).s(color).p("EA2sBJIIPo1Q").es().f(color).p("EAZoA8oYhGAAgyAyAABGYAAA8AyAyBGAAYA8AAA8gyAAg8YAAhGg8gyg8AA").cp().ef().f(color).p("EBjYA8oYg8AAg8AyAABGYAAA8A8AyA8AAYBGAAAygyAAg8YAAhGgygyhGAA").cp().ef();
            break;
        case 'relais_with_diode_closed':
            graphics.f(background).ss(line_width).s(color).p("EBOIAAAI/QPeIfQPyIAA/Q").cp().ef().es().ss(line_width).s(color).p("EAu4AfQIAA/Q").es().ss(line_width).s(color).p("EAAAA+gIZoAAMAAAgu4IMMgKMAxOAAAIMMAKMAAAAu4IZyAA").es().ss(line_width).s(color).p("EBZOBtYMAAAhGUMg1mAAAMAAABGUMA1mAAA").cp().es().f(background).ss(line_width).s(color).p("EBUYAz4IAAVQMgr6AAAIAA1QMAr6AAA").cp().ef().es().ss(line_width).s(color).p("EAZoA+gIO2AA").es().ss(line_width).s(color).p("EBUYA+gIO2AA").es().ss(line_width).s(color).p("EA+WBiwIAK5oIAAAA").es().ss(line_width).s(color).p("EA2sBJIIPo1Q").es().f(color).p("EAZoA8oYhGAAgyAyAABGYAAA8AyAyBGAAYA8AAA8gyAAg8YAAhGg8gyg8AA").cp().ef().f(color).p("EBjYA8oYg8AAg8AyAABGYAAA8A8AyA8AAYBGAAAygyAAg8YAAhGgygyhGAA").cp().ef().ss(line_width).s(color).p("EB9ABdwMgu4AAAIAANm").es().ss(line_width).s(color).p("EAAABdwMAu4AAAMAjKALu").es().ss(line_width).s(color).p("EAnEBnmILuAAIAACCIImjwIomkOIAACgIruAA").es();
            break;
        case 'dc_voltage_terminal':
            graphics.f(background).ss(line_width).s(color).moveTo(0, 100).lineTo(400, 100);
            break;
        case 'amperemeter':
        case 'voltmeter':
        case 'ohmmeter':
            graphics.f(background).ss(line_width).s(color).p("EBdwAfQMhdwAAA").ef().es().f(background).ss(line_width).s(color).p("EBOIAfQYAARMuEOExMAAYxWAAt6uEAAxMYAAxWN6t6RWAAYRMAAOEN6AARW").cp().ef().es();
            break;
        case 'wattmeter':
            graphics.f(background).ss(line_width).s(color).p('EBdwAu4MhdwAAA').es().ef().f(background).ss(line_width).s(color).p('EAvCBdwMAAAhdw').es().ef().f(background).ss(line_width).s(color).p('EBOIAu4cAAAARRgN/AN/gRRAAAcgRRAAAgN/gN/AAAgRRcAAAgRRAN/gN/ARRAAAcARRAAAAN/AN/AAAARR').es().ef().cp()
            break;
        case 'oscilloscope':
            graphics.f(background).ss(line_width).s(color).p("EBdwAfQMhdwAAA").ef().es().f(background).ss(line_width).s(color).p("EBOIAfQYAARMuEOExMAAYxWAAt6uEAAxMYAAxWN6t6RWAAYRMAAOEN6AARW").cp().ef().es().ss(10).s(color).p("EAXcAnEIXcvoIAAPoIXcvo").es();
            break;
        case 'fuse':
            graphics.f(background).ss(line_width).s(color).p('EA2sAH0Mgu4AAAMAAAAPoMAu4AAAMAAAgPo').es().ef().ss(line_width).s(color).p('EA+gAPoMg+gAAA').es().cp();
            break;
        case 'solar_cell':
            graphics.ss(6).s(0x000000).p('EAAAAfQMAH0AAAMA2sAAA').es().f(0xffffff).ss(6).s(0x000000).p('EA2sAyyMgu4AAAMAAAgnEMAu4AAAMAAAAnE').es().ef().f(0x000000).p('EAH0ALuMATiATiMgTiATiMAAAgnE').ef().cp();
            break;
    }
};

var re = {};
re['line_width'] = 6;
re['color'] = 0x000000;
re['background'] = 0xFFFFFF;
re['alpha'] = 1;
re['draw'] = function (graphics, type) {
    var translation = eappTranslationManager.t('options.wiring_symbols');

    if (translation == 'DIN') {
        draw_DIN(graphics, type);
    } else if (translation == 'IEC') {
        draw_IEC(graphics, type);
    } else if (translation == 'ANSI') {
        draw_ANSI(graphics, type);
    }
};

module.exports = re;
