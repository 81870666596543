'use strict';

var i18n = require('../lib/i18next');

var TranslationManager = function(TRANSLATIONS) {
    var options = {
        debug: true,
        lng: 'de-DE',
        getAsync: false
    };

    if (TRANSLATIONS) {
        options['customLoad'] = function(lng, ns, options, loadComplete) {
            // load the file for given language and namespace
            var xmlhttp = new XMLHttpRequest();
            var url = TRANSLATIONS[lng + '/' + ns];
            xmlhttp.open('GET', url, true);
            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState == 4) {
                    if(xmlhttp.status < 400) {
                        var data = JSON.parse(xmlhttp.responseText);
                        loadComplete(null, data);
                    } else {
                        loadComplete(null, null);
                    }

                }
            };
            xmlhttp.send();
        };
    } else {
        options['resStore'] = {
            'de-DE': {'translation': require('../locales/de-DE/de-DE.json')},
            'en-GB': {'translation': require('../locales/en-GB/en-GB.json')},
            'es-ES': {'translation': require('../locales/es-ES/es-ES.json')},
            'fr-FR': {'translation': require('../locales/fr-FR/fr-FR.json')},
            'ru-RU': {'translation': require('../locales/ru-RU/ru-RU.json')}
        };
    }

    i18n.init(options);
};

var p = TranslationManager.prototype;
module.exports = TranslationManager;

p.t = function(key) {
    return i18n.t(key);
};

p.addResources = function(lng, resources) {
    i18n.addResourceBundle(lng, 'translation', resources);
};

Object.defineProperty(p, 'lng', {
    get: function() {
        return i18n.lng();
    },
    set: function(lng) {
        i18n.setLng(lng);
    }
});