'use strict';

var PartView = require('./part_view');

var OpenerView = function(part) {
    PartView.call(this, part);

    this._show_port_label = true;
    this._function_digits = ['2', '1'];
    this._update_port_label();
};

var p = OpenerView.prototype = Object.create(PartView.prototype);
module.exports = OpenerView;