'use strict';

var Label = require('./label'),
    Arrow = require('./arrow'),
    TextLabel = require('./text_label'),
    grid_helper = require('../helper/grid_helper');

/**
 * An arrow to describe the orientation of something
 * @class ArrowLabel
 * @extends Label
 * @param {Object}  props   The properity object that defines the ArrowLabel
 * @constructor
 */
var ArrowLabel = function (props) {
    var start = props.points.start;
    var end = props.points.end;
    var id = props.id || '';
    var text = props.text || '';

    Label.call(this);

    this.id = id;
    this.name = 'Arrow' + this.name;
    this._text = text;

    this._label = new TextLabel(this._text);

    /**
     * A point to describe the center point of the arrow
     * @type {PIXI.Point}
     */

    start = grid_helper.grid_to_local(start.x, start.y);
    end = grid_helper.grid_to_local(end.x, end.y);

    this._center_point = this._calculate_center_point(start, end);
    this._normal = this._calculate_normal(start, end);
    this._arrow = new Arrow(start, end);

    this._starting_point = start;
    this._end_point = end;

    this.addChild(this._arrow);
    this.addChild(this._label);
};

var p = ArrowLabel.prototype = Object.create(Label.prototype);
module.exports = ArrowLabel;

/**
 * Calculates the point between a given start and end point
 * @param  {PIXI.Point} start
 * @param  {PIXI.Point} end
 * @return {PIXI.Point}
 */
p._calculate_center_point = function (start, end) {
    /* Simple vector calculation to determine the center point */
    var center_point = new PIXI.Point();
    center_point.x = end.x - start.x;
    center_point.y = end.y - start.y;

    center_point.x /= 2;
    center_point.y /= 2;

    center_point.x += start.x;
    center_point.y += start.y;

    return center_point;
};

p._calculate_normal = function(start, end) {
    var dx = end.x - start.x;
    var dy = end.y - start.y;
    var norm = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    dx /= norm;
    dy /= norm;
    if (dx > 0) {
        dx *= -1;
    }
    if (dy < 0) {
        dy *= -1;
    }
    //console.log('CALCULATE NORMAL', start, end, { 'x': dy, 'y': dx });
    return {
        'x': dy,
        'y': dx
    };
};

Object.defineProperty(p, 'starting_point', {
    get: function() {
        return this._starting_point;
    }
});

Object.defineProperty(p, 'end_point', {
    get: function() {
        return this._end_point;
    }
});