module.exports={
    "part_names": {
        "measuring_tip":"Pointe de mesure",
        "diode": "Diode",
        "z_diode": "Diode Z",
        "led": "LED",
        "led_red": "LED rouge",
        "led_green": "LED verte",
        "led_orange": "LED orange",
        "led_yellow": "LED jaune",
        "dc_motor": "Moteur CC",
        "shunt_dc_motor": "Moteur shunt",
        "series_dc_motor": "Moteur série",
        "lamp": "Lampe",
        "push_button": "Bouton poussoir",
        "push_button_pushed": "Bouton poussoir",
        "push_button_no_on": "Bouton poussoir",
        "push_button_no_off": "Bouton poussoir",
        "push_button_nc_on": "Bouton poussoir",
        "push_button_nc_off": "Bouton poussoir",
        "ldr_resistor": "LDR",
        "mosfet_p_left": "Canal P MOSFET",
        "mosfet_p_right": "Canal P MOSFET",
        "mosfet_n_left": "Canal N MOSFET",
        "mosfet_n_right": "Canal N MOSFET",
        "fet_p_left": "Canal P FET",
        "fet_p_right": "Canal P FET",
        "fet_n_left": "Canal N FET",
        "fet_n_right": "Canal N FET",
        "npn": "Transistor NPN",
        "pnp": "Transistor PNP",
        "npn_right": "Transistor NPN",
        "npn_left": "Transistor NPN",
        "pnp_right": "Transistor PNP",
        "pnp_left": "Transistor PNP",
        "ntc": "NTC",
        "ptc": "PTC",
        "coil_2": "Bobine",
        "coil": "Bobine",
        "capacitor": "Condensateur",
        "potentiometer": "Potentiomètre",
        "car_battery": "Batterie auto",
        "dc_voltage_source": "Source de tension continue",
        "resistor": "Résistance",
        "switch_on": "Commutateur",
        "switch_off": "Commutateur",
        "switch_no_on": "Commutateur",
        "switch_no_off": "Commutateur",
        "switch_nc_on": "Commutateur",
        "switch_nc_off": "Commutateur",
        "opener_on": "Contact à ouverture",
        "opener_off": "Contact à ouverture",
        "fuse": "Fusible",
        "toggle_switch_bottom": "Interrupteur va-et-vient",
        "toggle_switch_top": "Interrupteur va-et-vient",
        "relais_with_diode": "Relais avec diode de roue libre",
        "relais_with_diode_closed": "Relais avec diode de roue libre",
        "contactor": "Contacteur",
        "ground": "Masse",
        "dc_voltage_terminal": "Source de tension continue",
        "wire": "Câble",
        "amperemeter": "Ampèremètre",
        "voltmeter": "Voltmètre",
        "ohmmeter": "Ohmmètre",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimètre",
        "wattmeter": "Wattmètre",
        "voltage_arrow_label": "Flèche de tension",
        "current_arrow_label": "Flèche de courant",
        "minus": "Moins",
        "plus": "Plus",
        "ampere_plus": "Courant",
        "ampere_minus": "Courant",
        "voltage_plus": "Tension",
        "voltage_minus": "Tension",
        "dot": "Point de contact",
        "multiselection": "Sélection multiple",
        "solar_cell": "Cellule solaire"
    },
    "part_label": {
        "measuring_tip":"M T",
        "diode": "V",
        "z_diode": "V",
        "led": "V",
        "dc_motor": "M",
        "shunt_dc_motor": "M",
        "series_dc_motor": "M",
        "lamp": "E",
        "push_button": "S",
        "push_button_pushed": "S",
        "push_button_no_on": "S",
        "push_button_no_off": "S",
        "push_button_nc_on": "S",
        "push_button_nc_off": "S",
        "ldr_resistor": "R",
        "mosfet_p_left": "V",
        "mosfet_p_right": "V",
        "mosfet_n_left": "V",
        "mosfet_n_right": "V",
        "fet_p_left": "V",
        "fet_p_right": "V",
        "fet_n_left": "V",
        "fet_n_right": "V",
        "npn_right": "V",
        "npn_left": "V",
        "pnp_right": "V",
        "pnp_left": "V",
        "ntc": "R",
        "ptc": "R",
        "coil_2": "L",
        "coil": "L",
        "capacitor": "C",
        "dot": "",
        "potentiometer": "R",
        "car_battery": "G",
        "dc_voltage_source": "G",
        "dc_voltage_terminal": "G",
        "resistor": "R",
        "switch_on": "S",
        "switch_off": "S",
        "switch_no_on": "S",
        "switch_no_off": "S",
        "switch_nc_on": "S",
        "switch_nc_off": "S",
        "opener_on": "S",
        "opener_off": "S",
        "fuse": "F",
        "toggle_switch_bottom": "S",
        "toggle_switch_top": "S",
        "relais_with_diode": "K",
        "relais_with_diode_closed": "K",
        "contactor": "Q",
        "ground": "G",
        "voltage_arrow_label": "U",
        "current_arrow_label": "I",
        "amperemeter": "A",
        "voltmeter": "V",
        "ohmmeter": "\u03a9",
        "wattmeter": "P",
        "oscilloscope": "O",
        "solar_cell": "X"
    },
    "partCategories": {
        "resistors": "Résistances",
        "capacitors": "Condensateurs et bobines",
        "electricalSwitches": "Commutateurs électroniques",
        "mechanicalSwitches": "Commutateurs mécaniques",
        "electroMechanicalSwitches": "Commutateurs électromécaniques",
        "illuminant": "Lampe",
        "motors": "Moteurs",
        "powerSupply": "Alimentation en tension",
        "measurement": "Instruments de mesure",
        "tools": "Outils",
        "amperemeter": "Ampèremètre",
        "voltmeter": "Voltmètre",
        "ohmmeter": "Ohmmètre",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimètre",
        "wattmeter": "Wattmètre",
        "kfz": "Automobile"
    },
    "label": {
        "voltage_arrow_label": "Flèche de tension",
        "current_arrow_label": "Flèche de courant",
        "voltage": "Tension",
        "ampere": "Courant",
        "resistance": "Résistance",
        "inductance": "Inductance",
        "capacity": "Capacité",
        "z_voltage": "Tension de claquage",
        "min_resistance": "Résistance minimale",
        "max_resistance": "Résistance maximale",
        "divider": "Résistance actuelle",
        "light": "Intensité lumineuse",
        "temperature": "Température",
        "load": "Charge",
        "shortcircuit": "Court-circuit",
        "infinity": "Infini",
        "contact": "Contact",
        "nc": "NC",
        "no": "NO",
        "ratedCurrent": "Intensité électrique de référence",
        "left": "Gauche",
        "right": "Droite",
        "transistor_basis": "Contact de base",
        "irradiance": "Éclairement"
    },
    "options": {
        "fahrenheit": false,
        "wiring_symbols": "DIN",
        "passive_sign_convention": false,
        "commaSeperator": true
    },
    "circuit_types": {
        "generic": "Générique",
        "enclosure": "Armoire électrique",
        "domestic_installation": "Installation domestique",
        "automobile": "Automobile"
    },
    "toolnames": {
        "multiselect": "Sélection multiple",
        "wire": "Outil à câbles",
        "voltageLabel": "Flèche de tension",
        "currentLabel": "Flèche de courant"
    },
    "ui": {
        "simulation": "Simulation",
        "editor": "Editeur",
        "toolbar": "Outils",
        "part_list": "Composants",
        "save": "Sauvegarder",
        "circuit": "Circuit électrique",
        "new_circuit": "Nouveau circuit électrique",
        "new": "Nouveau",
        "reset": "Remettre à zéro",
        "quality": "Qualité",
        "magnify": "Agrandir",
        "decrease": "Diminuer",
        "maximize": "Maximiser",
        "minimize": "Minimiser",
        "name": "Nom",
        "rotate": "Tourner",
        "delete": "Supprimer",
        "perspective": "Perspective",
        "overview": "Vue d'ensemble",
        "oscilloscope": "Oscilloscope",
        "multimeter": "Multimètre",
        "wattmeter": "Wattmètre",
        "toggle": "Commuter",
        "edit": "Editer",
        "simulate": "Simuler",
        "zoom_in": "Zoom in",
        "zoom_out": "Zoom out",
        "2d_view": "Vue 2D",
        "scheme": "Vue 2D",
        "3d_view": "Vue 3D",
        "iso": "Vue 3D",
        "views": "Vues",
        "options": "Options",
        "file": "Fichier",
        "subscript": "Indice",
        "open": "Ouvrir",
        "save_as": "Sauvegarder sous",
        "settings": "Paramètres",
        "close": "Fermer",
        "release": "Libérer",
        "partDestroyability": "Composants destructibles",
        "showContactLabels": "Inscriptions des contacts",
        "startView3d": "Vue 3D au démarrage",
        "openSideMenuOnStart": "Barre latérale déployée au démarrage",
        "voltageEditable": "Sources de tension réglables",
        "cancel": "Annuler",
        "ok": "Ok",
        "parts": "Composants",
        "tools": "Outils",
        "color": "Couleur",
        "timer": {
            "start": "START",
            "pause": "PAUSE",
            "reset": "RESET"
        }
    },
    "rights":{
        "edit":"Editer"
    }
}
